import React from "react";
import { Link } from "react-router-dom";
import { getAllNotifications } from "../../models/notifications";

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("veeks_user_info");
  window.location.href = "/";
}

export class TopMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      notifications: [],
      page_input: {
        page: 1,
        limit: 5,
      },
      total_count: 0,
    };
    this.getLatestNotifications = this.getLatestNotifications.bind(this);
  }

  componentDidMount() {
    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.getLatestNotifications(q);
  }

  async getLatestNotifications(q) {
    this.setState({ is_loading: true });
    let response = await getAllNotifications(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({
        notifications: response.data,
        total_count: meta.total_not_opened,
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  triggerParent() {
    this.props.notificationChangeReset(true);
    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.getLatestNotifications(q);
  }

  render() {
    let is_trigger = this.props.notification_trigger;
    if (is_trigger) {
      this.triggerParent();
    }
    return (
      <React.Fragment>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <button
                className="btn btn-outline-link"
                data-widget="pushmenu"
                type="button"
              >
                <i className="fas fa-bars"></i>
              </button>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <h5 style={{ marginTop: "0.4rem" }}>
                {this.props.title || "Home"}
              </h5>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a
                className="nav-link"
                data-widget="navbar-search"
                href={true}
                role="button"
              >
                <i className="fas fa-search"></i>
              </a>
              <div className="navbar-search-block">
                <form className="form-inline">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control form-control-navbar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-navbar" type="submit">
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        className="btn btn-navbar"
                        type="button"
                        data-widget="navbar-search"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li> */}

            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href={true}>
                <i className="far fa-bell"></i>
                <span className="badge badge-warning navbar-badge">
                  {this.state.total_count}
                </span>
              </a>
              {this.state.notifications.length > 0 && (
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  {this.state.notifications.map((item, i) => {
                    return (
                      <a href={true} className="dropdown-item">
                        <div className="media">
                          <div className="media-body">
                            <h3 className="dropdown-item-title">
                              {item.title}
                              <span className="float-right text-sm text-warning">
                                <i className="fas fa-star"></i>
                              </span>
                            </h3>
                            <p className="text-sm">
                              {item.description.substring(0, 50)}...
                            </p>
                            <p className="text-sm text-muted">
                              <i className="far fa-clock mr-1"></i>{" "}
                              {item.created_at}
                            </p>
                          </div>
                        </div>
                      </a>
                    );
                  })}
                  <div className="dropdown-divider"></div>
                  <a
                    href="/notifications"
                    className="dropdown-item dropdown-footer"
                  >
                    See All Notifications
                  </a>
                </div>
              )}
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href={true}
                role="button"
              >
                <i className="fas fa-expand-arrows-alt"></i>
              </a>
            </li>

            <li className="nav-item">
              <Link
                exact={true}
                to={true}
                className="nav-link"
                onClick={logout}
              >
                <i className="nav-icon fas fa-sign-out-alt"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}
