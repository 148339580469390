import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import DataTable from "react-data-table-component";
import {
  createDistrict,
  getAllDistricts,
  updateDistrict,
  getDistrict,
  deleteDistrict,
} from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

export class Districts extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      modes: [],
      districts: [],
      subscriptions: [],
      subscription: [],
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 10,
      },
      input: {},
      update: {},
      query: "",
      is_loading: false,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Name",
          cell: (row) => row.name,
          sortable: true,
        },
        {
          name: "View",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-edit"></i>&nbsp; View
            </button>
          ),
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp;Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openView = this.openView.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.loadDistricts(q);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleUpdateChange(event) {
    let update = this.state.update;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "district") {
      this.loadAgencies("u");
    }
  }

  async loadDistricts(q) {
    this.setState({ is_loading: true });
    let response = await getAllDistricts(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ subscriptions: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    let q = new URLSearchParams(pinput).toString();
    this.loadFreeSubs(q);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.agency = this.state.input.agency.value;
      const response = await createDistrict(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadFreeSubs(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.update;
      payload.agency = this.state.update.agency.value;
      const response = await updateDistrict(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadFreeSubs(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id });
    this.getSubscriptionData(id);
  }

  async getSubscriptionData(id) {
    let response = await getDistrict(id);
    console.log(response);
    if (response.success) {
      this.setState({ update: response.data });
      this.loadAgencies("u");
      setTimeout(() => {
        let data = response.data;
        let { update } = this.state;
        update.agency = {
          value: data.agency_info.id,
          label: data.agency_info.name + " - " + data.agency_info.code,
        };
        this.setState({ update });
      }, 500);
    }
  }

  async removeRow(id) {
    let response = await deleteDistrict(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      let pinput = this.state.page_input;
      let q = new URLSearchParams(pinput).toString();
      this.loadFreeSubs(q);
    }
  }

  handleOptionChange = (selectedOption) => {
    let { input } = this.state;
    input.agency = selectedOption;
    this.setState({ input });
  };

  handleOptionChangeU = (selectedOption) => {
    let { update } = this.state;
    update.agency = selectedOption;
    this.setState({ update });
  };

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name + " - " + item.code };
    });
    return opt;
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Free Subscriptions</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isOpen: !this.state.isOpen });
                            }}
                          >
                            Add New
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of districts</h3>
                        </div>

                        <div className="card-body p-0">
                          <div className="row">
                            <div className="col-md-12">
                              {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.subscriptions.length > 0 && (
                                  <DataTable
                                    title=""
                                    fixedHeader
                                    columns={this.state.columns}
                                    data={this.state.subscriptions}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={15}
                                    paginationDefaultPage={
                                      this.state.currentPage
                                    }
                                    paginationRowsPerPageOptions={[
                                      15, 25, 50, 100,
                                    ]}
                                    paginationComponentOptions={{
                                      rowsPerPageText:
                                        "Subscriptions per page:",
                                      rangeSeparatorText: "out of",
                                    }}
                                    onChangePage={this.handlePageChange}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          show={this.state.isOpen}
          onHide={() => {
            this.setState({ isOpen: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="add-scheme">Add New</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="name"
                                  value={this.state.input.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-save" />
                &nbsp; Save
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                <i className="fa fa-times" />
                &nbsp; Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          size="xl"
          show={this.state.popup}
          onHide={() => {
            this.setState({ popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">View/Edit</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleUpdate}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter district name"
                                  name="name"
                                  value={this.state.update.name}
                                  onChange={this.handleUpdateChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-save" />
                &nbsp; Save
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  this.setState({ popup: false });
                }}
              >
                <i className="fa fa-times" />
                &nbsp; Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
