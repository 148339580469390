import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";
const user = getLoggedUser();

async function getBranches(q) {
  return fetch(apiUrl + "branches?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getBranch(id) {
  return fetch(apiUrl + "branches/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function createBranch(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "branches",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function updateBranch(payload, id) {
  return fetch(apiUrl + "branches/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteBranch(id) {
  return fetch(apiUrl + "branches/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export { getBranches, getBranch, createBranch, updateBranch, deleteBranch };
