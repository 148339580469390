import React from "react";

export class LedgerRow extends React.Component {
  constructor() {
    super();
    this.state = {
      is_hide: false,
      input: {
        received_amount: 0,
        paid_amount: 0,
        balance: 0,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let data = this.props.data;
    let { input } = this.state;
    // input = {};
    // this.setState({ input });
    this.props.setDataArray(input, data.id);
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    this.addAmounts();
  }

  addAmounts() {
    let { input } = this.state;
    input.received_amount = Number(input.received_amount);
    input.paid_amount = Number(input.paid_amount);
    input.balance = Number(input.received_amount) - Number(input.paid_amount);
    this.setState({ input });
    let data = this.props.data;
    this.props.setDataArray(input, data.id);
  }
  render() {
    let data = this.props.data;
    return (
      <React.Fragment>
        <tr>
          <td>{data.name}</td>
          <td>{data.code}</td>
          <td>
            <input
              type="text"
              className="table-input-lg"
              name="received_amount"
              value={this.state.input.received_amount}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input-lg"
              name="paid_amount"
              value={this.state.input.paid_amount}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input-lg"
              readOnly={true}
              name="addadvance"
              value={this.state.input.balance}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
