import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { facebookLogin } from "../../models/users";
import swal from "sweetalert";

export class FacebookSignin extends React.Component {
  onSuccess = async (res) => {
    let response = await facebookLogin(res);
    if ("token" in response.data) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "veeks_user_info",
            JSON.stringify(response.data)
          );
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  render() {
    let buttonText = "Facebook";
    if (this.props.buttonText) {
      buttonText = this.props.buttonText;
    }
    return (
      <React.Fragment>
        <FacebookLogin
          appId="462331852452465"
          onSuccess={(response) => {
            console.log("Login Success!", response);
          }}
          onFail={(error) => {
            console.log("FB Login Failed!", error);
          }}
          onProfileSuccess={this.onSuccess}
          render={({ onClick }) => (
            <button
              className="btn btn-white me-2 btn-md btn-block text-left"
              onClick={onClick}
            >
              <img
                src="../assets/images/svgs/svg/facebook.svg"
                className="h-5 w-5"
                alt=""
              />
              <span className="p-2 font-weight-normal">{buttonText}</span>
            </button>
          )}
        />
      </React.Fragment>
    );
  }
}
