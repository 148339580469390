import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const endpoint = `${apiUrl}routes`;
const user = getLoggedUser();

async function createRoute(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllRoutes(q) {
  return fetch(endpoint + "?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getRoute(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateRoute(payload, id) {
  return fetch(endpoint + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteRoute(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export { createRoute, getAllRoutes, getRoute, updateRoute, deleteRoute };
