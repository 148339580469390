import React from "react";
import { Link } from "react-router-dom";
import registerUser from "../../models/register";
import { SocialSignin } from "./socialSignin";
import swal from "sweetalert";
import contryData from "../../constants/country.json";
import i18n from "../../i18n";
let cur_lng = localStorage.getItem("I18N_LANGUAGE");
const codeData = [...contryData];
export class Registration extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        country_code: "+968",
        is_staff: false,
      },
      civilCard: null,
      t_c_acept: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 2MB", "error");
      return;
    }
    if (event.target.name === "civilCard") {
      this.setState({ civilCard: event.target.files[0] });
    }
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  validateFileSize(file) {
    const MAX_FILE_SIZE = 2048; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("name", this.state.input.name);
        formData.append("country_code", this.state.input.country_code);
        formData.append("mobile", this.state.input.mobile);
        formData.append("email", this.state.input.email);
        formData.append("username", this.state.input.username);
        formData.append("password", this.state.input.password);
        formData.append("confirm_password", this.state.input.confirm_password);
        formData.append("civil_id_number", this.state.input.civil_id_number);
        formData.append("is_staff", this.state.input.is_staff);
        if (this.state.civilCard) {
          formData.append(
            "civilCard",
            this.state.civilCard,
            this.state.civilCard.name
          );
        }
        const response = await registerUser(formData);
        if ("token" in response.data) {
          // let sc_msg = this.state.input.is_staff
          //   ? i18n.t("reg_success_staff")
          //   : i18n.t("reg_success");
          swal("Success", i18n.t("reg_success"), "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              window.location.href = "/login";
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  resetForm() {
    let input = {};
    input["name"] = "";
    input["mobile"] = "";
    input["email"] = "";
    input["username"] = "";
    input["country_code"] = "+968";
    input["password"] = "";
    input["confirm_password"] = "";
    input["civil_id_number"] = "";
    this.setState({ input: input });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
      swal("Failed", errors["name"], "error");
      return isValid;
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
      swal("Failed", errors["username"], "error");
      return isValid;
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = "Please enter phone country code.";
      swal("Failed", errors["country_code"], "error");
      return isValid;
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
      swal("Failed", errors["mobile"], "error");
      return isValid;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
      swal("Failed", errors["email"], "error");
      return isValid;
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
      swal("Failed", errors["password"], "error");
      return isValid;
    }
    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Please enter Confirm Password.";
      swal("Failed", errors["confirm_password"], "error");
      return isValid;
    }
    if (input["password"] !== input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Password didn't match.";
      swal("Failed", errors["confirm_password"], "error");
      return isValid;
    }

    if (!input["civil_id_number"]) {
      isValid = false;
      errors["civil_id_number"] = "Please enter your Civil ID Number.";
      swal("Failed", errors["civil_id_number"], "error");
      return isValid;
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-primary ">
                  <h1 className="auction-title-cust auction-title-cust-1">
                    {i18n.t("registration")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb background-custom">
          <div className="container customerpage">
            <div className="row">
              <div className="single-page">
                <div className="col-lg-6 col-xl-6 col-md-6 d-block mx-auto">
                  <div className="wrapper wrapper2">
                    <form
                      id="Register"
                      className="card-body pt-7"
                      tabindex="500"
                      autoComplete="off"
                      onSubmit={this.handleSubmit}
                    >
                      {/* <h3>{i18n.t("registration")}</h3> */}
                      <div className="name">
                        <input
                          type="text"
                          name="name"
                          value={this.state.input.name}
                          onChange={this.handleChange}
                          autoComplete="name"
                        />
                        <label>
                          {i18n.t("name")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.name}
                        </div>
                      </div>

                      <div className="input-group input-group-sm mt-2">
                        {/* <select
                          className="form-control"
                          data-placeholder="Select Country"
                          style={{
                            height: "50px",
                            padding: "5px 15px",
                            border: "solid 1px #d8dde6",
                            "z-index": 0,
                          }}
                          name="country_code"
                          value={this.state.input.country_code}
                          onChange={this.handleChange}
                        >
                          <option value="">--Country--</option>
                          {codeData.map((item, i) => {
                            return (
                              <option value={item.dial_code}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select> */}
                        <span className="form-control country-flag-field">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/flags/ar.svg"
                            }
                            alt=""
                          />
                        </span>
                        <input
                          type="text"
                          className="form-control col-9"
                          style={{ "z-index": 0 }}
                          name="mobile"
                          value={this.state.input.mobile}
                          onChange={this.handleChange}
                        />
                        <label>
                          {i18n.t("phone_number")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.mobile}
                        </div>
                      </div>
                      <div className="email">
                        <input
                          type="text"
                          name="email"
                          value={this.state.input.email}
                          onChange={this.handleChange}
                          style={{ direction: "ltr" }}
                        />
                        <label>
                          {i18n.t("email")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      </div>

                      <div className="username">
                        <input
                          type="text"
                          name="username"
                          value={this.state.input.username}
                          onChange={this.handleChange}
                          autoComplete="username"
                        />
                        <label>
                          {i18n.t("username")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.username}
                        </div>
                      </div>

                      <div className="passwd">
                        <input
                          type="password"
                          name="password"
                          value={this.state.input.password}
                          onChange={this.handleChange}
                        />
                        <label>
                          {i18n.t("password")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      </div>
                      <div className="confirm_password">
                        <input
                          type="password"
                          name="confirm_password"
                          value={this.state.input.confirm_password}
                          onChange={this.handleChange}
                        />
                        <label>
                          {i18n.t("confirm_password")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.confirm_password}
                        </div>
                      </div>

                      <div className="input-group input-group-sm mt-2">
                        <input
                          type="text"
                          className="form-control col-12"
                          style={{ "z-index": 0 }}
                          name="civil_id_number"
                          value={this.state.input.civil_id_number}
                          onChange={this.handleChange}
                        />
                        <label>
                          {i18n.t("civil_id_number")}
                          <span className="text text-danger">*</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.civil_id_number}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ textAlign: "left" }}
                          >
                            {i18n.t("attacth_civil_card")}{" "}
                            <span className="text text-danger">*</span>
                          </label>
                          <input
                            className=""
                            type="file"
                            placeholder=""
                            name="civilCard"
                            onChange={this.onFileChange}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          textAlign: cur_lng === "ar" ? "right" : "left",
                        }}
                      >
                        <input
                          style={{
                            height: "15px",
                            padding: "0",
                            width: "15px",
                          }}
                          type="checkbox"
                          name="is_staff"
                          checked={this.state.input.is_staff}
                          onChange={(event) => {
                            let { input } = this.state;
                            input.is_staff = !this.state.input.is_staff;
                            this.setState({
                              input: input,
                            });
                          }}
                        />{" "}
                        <span className="text text-dark">
                          {i18n.t("is_staff_label")}
                        </span>
                      </div>

                      <div
                        style={{
                          textAlign: cur_lng === "ar" ? "right" : "left",
                        }}
                      >
                        <input
                          style={{
                            height: "15px",
                            padding: "0",
                            width: "15px",
                          }}
                          type="checkbox"
                          name="t_c"
                          checked={this.state.t_c_acept}
                          onChange={(event) => {
                            this.setState({
                              t_c_acept: !this.state.t_c_acept,
                            });
                          }}
                        />{" "}
                        <span className="text text-dark">
                          {i18n.t("please_accept")}{" "}
                          <a href="/terms" target="_new">
                            {i18n.t("terms_conditions")}
                          </a>
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={!this.state.t_c_acept}
                      >
                        {i18n.t("signup")}
                      </button>

                      <p className="text-dark mb-0">
                        {i18n.t("alreadyhaveaccount")}
                        <Link to="/login"> {i18n.t("login")} </Link>
                      </p>
                    </form>
                    <SocialSignin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
