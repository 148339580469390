import React from "react";
import { SideBar } from "../layouts/sidebar";
import { TopMenu } from "../layouts/top_menu";
import DataTable from "react-data-table-component";
import {
  getAllNotifications,
  getNotification,
  updateNotification,
} from "../../models/notifications";
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { tableCustomStyles } from "../../constants/styles";

const conditionalRowStyles = [
  {
    when: (row) => row.is_opened === 0,
    style: {
      backgroundColor: "#ffc3c3",
    },
  },
];

export class Notifications extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 15,
      },
      notifications: [],
      search: {},
      input: {},
      update: {},
      query: "",
      is_loading: false,
      is_notification_changed: false,
      columns: [
        {
          name: "ID",
          sortable: true,
          wrap: true,
          width: "100px",
          cell: (row) => row.id,
        },
        {
          name: "Title",
          cell: (row) => row.title,
        },
        {
          name: "Description",
          cell: (row) => row.description,
          wrap: true,
        },
        {
          name: "View",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-eye"></i>&nbsp; View
            </button>
          ),
        },
      ],
    };

    this.openView = this.openView.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.notificationChangeReset = this.notificationChangeReset.bind(this);
  }
  componentDidMount() {
    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.loadAll(q);
  }

  async loadAll(q) {
    this.setState({ is_loading: true });
    let response = await getAllNotifications(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ notifications: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    let q = new URLSearchParams(pinput).toString();
    this.loadAll(q);
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id });
    this.getData(id);
  }

  async getData(id) {
    let response = await getNotification(id);
    if (response.success) {
      let data = response.data;
      let is_opened = data.is_opened;
      this.setState({ update: data });
      let payload = data;
      payload.is_opened = 1;
      if (!is_opened) {
        this.setState({ is_notification_changed: true });
        let update_res = await updateNotification(payload, id);
        if (update_res.success) {
          let input = this.state.page_input;
          let q = new URLSearchParams(input).toString();
          this.loadAll(q);
        }
      }
    }
  }

  notificationChangeReset(reset) {
    if (reset) {
      this.setState({ is_notification_changed: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu
              title="Notifications"
              notification_trigger={this.state.is_notification_changed}
              notificationChangeReset={this.notificationChangeReset}
            />
            <SideBar />
            <div className="content-wrapper">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of notifications</h3>
                        </div>

                        <div className="card-body p-0 overflow-hidden">
                          <div className="row">
                            <div className="col-md-12">
                              {/* {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )} */}

                              {this.state.notifications.length > 0 && (
                                <DataTable
                                  customStyles={tableCustomStyles}
                                  title=""
                                  fixedHeader
                                  columns={this.state.columns}
                                  data={this.state.notifications}
                                  highlightOnHover
                                  pagination
                                  paginationServer
                                  paginationTotalRows={this.state.totalRows}
                                  paginationPerPage={15}
                                  paginationDefaultPage={this.state.currentPage}
                                  paginationRowsPerPageOptions={[
                                    15, 25, 50, 100,
                                  ]}
                                  paginationComponentOptions={{
                                    rowsPerPageText: "Notifications per page:",
                                    rangeSeparatorText: "out of",
                                  }}
                                  onChangePage={this.handlePageChange}
                                  conditionalRowStyles={conditionalRowStyles}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.popup}
          onHide={() => {
            this.setState({ popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-scheme">Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-secondary">
                      <div className="card-body">
                        <h3>{this.state.update.title}</h3>
                        <p>{this.state.update.description}</p>
                        <p className="text-dark text-right">
                          Created at : {this.state.update.created_at}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => {
                this.setState({ popup: false });
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
