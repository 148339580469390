import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const endpoint = apiUrl + "advertisements";
const user = getLoggedUser();

async function createAdvertisement(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllAdvertisements(q) {
  return fetch(endpoint + "?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAdvertisement(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAdvertisementByDist(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "get_ads_by_dist",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function updateAdvertisement(payload, id) {
  return fetch(endpoint + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteAdvertisement(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateAdPayment(payload, id) {
  return fetch(endpoint + "/payment/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllClients() {
  return fetch(apiUrl + "ad_clients", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getLatestBillNumber() {
  return fetch(apiUrl + "get_bill_no", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createAdvertisement,
  getAllAdvertisements,
  getAdvertisement,
  updateAdvertisement,
  deleteAdvertisement,
  getAdvertisementByDist,
  updateAdPayment,
  getAllClients,
  getLatestBillNumber,
};
