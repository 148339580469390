import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { loginUser } from "../../models/login";
import i18n from "../../i18n";
import logo from "../../logo.png";

export default function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });

    if ("token" in response.data) {
      swal(i18n.t("success"), i18n.t("user_signed_in"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "veeks_user_info",
            JSON.stringify(response.data)
          );
          if (response.data.role === "A") {
            window.location.href = "/";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box pt-3">
        <div className="login-logo">
          <img src={logo} alt="" />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            {/* <p className="login-box-msg">Sign in to veekshanam NMS</p> */}

            <form id="login" onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  name="username"
                  onChange={(e) => setUserName(e.target.value)}
                  className="form-control"
                  placeholder="Username"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            {/* <p className="mb-1">
              <Link to="/forgotPassword">I forgot my password</Link>
            </p>
            <p className="mb-0">
              <Link to="/registration" className="text-center">
                Register a new membership
              </Link>
            </p> */}
          </div>
        </div>
        <footer className="main-footer m-0" style={{ fontSize: "12px" }}>
          <strong>
            Copyright &copy;
            <a href="#">Veekshanam.com</a>.
          </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
          </div>
        </footer>
      </div>
    </div>
  );
}
