import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllAgencys } from "../../../models/agencies";
import { getAllDistricts } from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { LabelRow } from "./helpers/label_row";
import { createLabels } from "../../../models/labels";

export class CreateLabel extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      districts: [],
      totalRows: 0,
      currentPage: 1,
      input: {},
      update: {},
      query: "",
      is_loading: false,
      data_arr: {},
      is_saving: false,
      incr: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.setDataArray = this.setDataArray.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadDistricts();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district" && event.target.value) {
      this.loadAgencies();
    }
  }

  async loadAgencies() {
    let { data_arr } = this.state;
    this.setState({ is_loading: true });
    data_arr = {};
    this.setState({ data_arr });
    this.setState({ agencies: {} });
    let q = new URLSearchParams({
      is_label: true,
      district: this.state.input.district,
    }).toString();
    let response = await getAllAgencys(q);
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ agencies: response.data });
    } else {
      this.setState({ agencies: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async saveData() {
    if (!this.state.input.label_date) {
      swal("Failed", "Please select a valid date.", "error");
      return;
    }
    if (!this.state.input.district) {
      swal("Failed", "Please select a district.", "error");
      return;
    }
    this.setState({ is_saving: true });
    let payload = this.state.input;
    payload.data = this.state.data_arr;
    let response = await createLabels(payload);
    if (response.success) {
      this.setState({ is_saving: false });
      this.setState({ data_arr: {} });
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          setTimeout(function () {
            window.location.reload();
          }, 50);
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ is_saving: false });
      swal("Failed", response.message, "error");
    }
  }

  setDataArray(arr, index) {
    let { data_arr, incr } = this.state;
    console.log("dd", data_arr);
    if (data_arr[index]) {
      delete data_arr[index];
    }
    data_arr[index] = arr;
    this.setState({ data_arr });

    console.log(incr);
    this.setState({ incr: incr + 1 });
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Create Label" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">
                            Filter by District
                          </h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputDate">Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="label_date"
                                  value={this.state.input.label_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title m-auto">
                            List of agencies ({this.state.agencies.length})
                          </h3>
                          {this.state.agencies.length > 0 && (
                            <button
                              className="btn btn-success"
                              onClick={this.saveData}
                              disabled={this.state.is_saving}
                            >
                              {this.state.is_saving ? "Saving..." : "Save All"}
                            </button>
                          )}
                        </div>

                        <div
                          className="card-body table-responsive p-0"
                          style={{ height: "450px" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                {this.state.is_loading && (
                                  <div className="text-center p-5">
                                    <Spinner animation="grow" size="lg" />
                                  </div>
                                )}

                                {!this.state.is_loading &&
                                  this.state.agencies.length > 0 && (
                                    <table class="table table-head-fixed table-striped">
                                      <thead>
                                        <tr>
                                          <th>SL#</th>
                                          <th>AGENCY</th>
                                          <th>ROUTE</th>
                                          <th>CODE</th>
                                          <th>DEF-SCHEME</th>
                                          <th>DEF-FREE</th>
                                          <th>DEF-PAID</th>
                                          <th>ADDITION -FREE</th>
                                          <th>ADDITION -PAID</th>
                                          <th>TOTAL -ADD</th>
                                          <th>TOTAL -FREE</th>
                                          <th>TOTAL -PAID</th>
                                          {/* <th>SHOW TOTAL</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.agencies.map((item, i) => {
                                          return (
                                            <LabelRow
                                              data={item}
                                              index={i}
                                              setDataArray={this.setDataArray}
                                            />
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
