import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import DataTable from "react-data-table-component";
import { getAllAgencys } from "../../../models/agencies";
import { getAllDistricts } from "../../../models/districts";
import {
  createBill,
  getAllBills,
  updateBill,
  getBill,
  deleteBill,
} from "../../../models/bills";
import { Spinner } from "react-bootstrap";
import { getReporterByAgency } from "../../../models/reporters";
import swal from "sweetalert";
import { getBranches } from "../../../models/branches";
import { Modal } from "react-bootstrap";
import Select from "react-select";

export class DailyBill extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      reporters: [],
      districts: [],
      branches: [],
      bills: [],
      bill: [],
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 10,
      },
      update: {},
      query: "",
      is_loading: false,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Date",
          cell: (row) => row.tmpdate,
          sortable: true,
        },
        {
          name: "Receipt No.",
          cell: (row) => row.comment,
          sortable: true,
        },
        {
          name: "Agency",
          sortable: true,
          cell: (row) => (row.agency_info ? row.agency_info.name : ""),
        },
        {
          name: "Reporter",
          cell: (row) =>
            row.reporter_info !== null ? row.reporter_info.name : "",
        },
        {
          name: "Amount",
          cell: (row) => row.amount,
          wrap: true,
        },
        {
          name: "View",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-edit"></i>&nbsp; View
            </button>
          ),
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp;Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openView = this.openView.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadAgencies();
    this.loadBranches();
    this.loadDistricts();

    let input = this.state.input;
    let q = new URLSearchParams(input).toString();
    this.loadbills(q);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district") {
      this.loadAgencies();
    }
    if (event.target.name === "agency") {
      this.loadReporters(event.target.value);
    }
  }

  handleUpdateChange(event) {
    let update = this.state.update;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "district") {
      this.loadAgencies("u");
    }
    if (event.target.name === "agency") {
      this.loadReporters(event.target.value);
    }
  }

  async loadReporters(agency) {
    let response = await getReporterByAgency({ agency: agency });
    if (response.success) {
      this.setState({ reporters: response.data });
    }
  }

  async loadAgencies(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    if (typeof dist === "undefined") {
      return;
    }
    let q = new URLSearchParams({
      district: dist,
    }).toString();

    let response = await getAllAgencys(q);
    if (response.success) {
      this.setState({ agencies: response.data });
      let { update } = this.state;
      if (type === "u") {
        this.setState({ update });
      }
    }
  }

  async loadBranches() {
    let response = await getBranches();
    if (response.success) {
      this.setState({ branches: response.data });
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadbills(q) {
    this.setState({ is_loading: true });
    let response = await getAllBills(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ bills: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadbills(qstring);
    console.log(page);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.agency = this.state.input.agency.value;
      const response = await createBill(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadbills(this.state.qstring);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.update;
      payload.agency = this.state.update.agency.value;
      const response = await updateBill(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadbills(this.state.qstring);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id });
    this.getBillData(id);
  }

  async getBillData(id) {
    let response = await getBill(id);
    console.log(response);
    if (response.success) {
      this.setState({ update: response.data });
      this.loadAgencies("u");
      this.loadReporters(response.data.agency);
      setTimeout(() => {
        let data = response.data;
        let { update } = this.state;
        update.agency = {
          value: data.agency_info.id,
          label: data.agency_info.name + " - " + data.agency_info.code,
        };
        this.setState({ update });
      }, 500);
    }
  }

  async removeRow(id) {
    let response = await deleteBill(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      this.loadbills(this.state.qstring);
    }
  }

  handleOptionChange = (selectedOption) => {
    let { input } = this.state;
    input.agency = selectedOption;
    this.setState({ input });
  };

  handleOptionChangeU = (selectedOption) => {
    let { update } = this.state;
    update.agency = selectedOption;
    this.setState({ update });
  };

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name + " - " + item.code };
    });
    return opt;
  }

  render() {
    let agencyOptions = this.selectOptions(this.state.agencies);
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Daily Bill" />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isOpen: !this.state.isOpen });
                            }}
                          >
                            Add New Bill
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of daily bills</h3>
                        </div>

                        <div className="card-body p-0">
                          <div className="row">
                            <div className="col-md-12">
                              {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.bills.length > 0 && (
                                  <DataTable
                                    title=""
                                    fixedHeader
                                    columns={this.state.columns}
                                    data={this.state.bills}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={15}
                                    paginationDefaultPage={
                                      this.state.currentPage
                                    }
                                    paginationRowsPerPageOptions={[
                                      15, 25, 50, 100,
                                    ]}
                                    paginationComponentOptions={{
                                      rowsPerPageText: "Bills per page:",
                                      rangeSeparatorText: "out of",
                                    }}
                                    onChangePage={this.handlePageChange}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          show={this.state.isOpen}
          onHide={() => {
            this.setState({ isOpen: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-bill">Add New Bill</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputDate">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Date"
                        name="tmpdate"
                        value={this.state.input.tmpdate}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputReceipt">Receipt Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt Number"
                        name="comment"
                        value={this.state.input.comment}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputDate">District</label>
                      <select
                        className="form-control"
                        name="district"
                        value={this.state.input.district}
                        onChange={this.handleChange}
                      >
                        <option value="">--Select--</option>
                        {this.state.districts.length > 0 &&
                          this.state.districts.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputReceipt">Agency</label>
                      <Select
                        name="agency"
                        className="form-control p-0 border-0 custom-select"
                        value={this.state.input.agency}
                        onChange={this.handleOptionChange}
                        options={agencyOptions}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputAmount">Executive's Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Executive's Name"
                        name="executive_name"
                        value={this.state.input.executive_name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="">Reporter</label>
                      <select
                        className="form-control"
                        name="reporter"
                        value={this.state.input.reporter}
                        onChange={this.handleChange}
                      >
                        <option value="">--Select--</option>
                        {this.state.reporters.length > 0 &&
                          this.state.reporters.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputAmount">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                        name="amount"
                        value={this.state.input.amount}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="">Payment Method</label>
                      <select
                        className="form-control"
                        name="type"
                        value={this.state.input.type}
                        onChange={this.handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="cash">Cash Mode</option>
                        <option value="cheque">Cheque/DD Mode</option>
                        <option value="upi">UPI</option>
                      </select>
                    </div>
                  </div>
                </div>

                {this.state.input.type === "cheque" && (
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Cheque/DD Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Cheque/DD Number"
                          name="cheque_no"
                          value={this.state.input.cheque_no}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Name"
                          name="bank"
                          value={this.state.input.bank}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Bank Branch</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Branch"
                          name="bank_branch"
                          value={this.state.input.bank_branch}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Commission</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Commission"
                          name="commission"
                          value={this.state.input.commission}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Cheque Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Cheque Date"
                          name="cheque_date"
                          value={this.state.input.cheque_date}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Save Bill
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          size="xl"
          show={this.state.popup}
          onHide={() => {
            this.setState({ popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-bill">View/Edit Bill</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleUpdate}>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputDate">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Date"
                        name="tmpdate"
                        value={this.state.update.tmpdate}
                        onChange={this.handleUpdateChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputReceipt">Receipt Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt Number"
                        name="comment"
                        value={this.state.update.comment}
                        onChange={this.handleUpdateChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputDate">District</label>
                      <select
                        className="form-control"
                        name="district"
                        value={this.state.update.district}
                        onChange={this.handleUpdateChange}
                      >
                        <option value="">--Select--</option>
                        {this.state.districts.length > 0 &&
                          this.state.districts.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputReceipt">Agency</label>
                      <Select
                        name="agency"
                        className="form-control p-0 border-0 custom-select"
                        value={this.state.update.agency}
                        onChange={this.handleOptionChangeU}
                        options={agencyOptions}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputAmount">Executive's Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Executive's Name"
                        name="executive_name"
                        value={this.state.update.executive_name}
                        onChange={this.handleUpdateChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="">Reporter</label>
                      <select
                        className="form-control"
                        name="reporter"
                        value={this.state.update.reporter}
                        onChange={this.handleUpdateChange}
                      >
                        <option value="">--Select--</option>
                        {this.state.reporters.length > 0 &&
                          this.state.reporters.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="inputAmount">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                        name="amount"
                        value={this.state.update.amount}
                        onChange={this.handleUpdateChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label for="">Payment Method</label>
                      <select
                        className="form-control"
                        name="type"
                        value={this.state.update.type}
                        onChange={this.handleUpdateChange}
                      >
                        <option value="">--Select--</option>
                        <option value="cash">Cash Mode</option>
                        <option value="cheque">Cheque/DD Mode</option>
                        <option value="upi">UPI</option>
                      </select>
                    </div>
                  </div>
                </div>

                {this.state.update.type === "cheque" && (
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputReceipt">Cheque/DD Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Cheque/DD Number"
                          name="cheque_no"
                          value={this.state.update.cheque_no}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputReceipt">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Name"
                          name="bank"
                          value={this.state.update.bank}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Bank Branch</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Branch"
                          name="bank_branch"
                          value={this.state.update.bank_branch}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Commission</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Commission"
                          name="commission"
                          value={this.state.update.commission}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputReceipt">Cheque Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Cheque Date"
                          name="cheque_date"
                          value={this.state.update.cheque_date}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary btn-sm">
                Save Bill
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ popup: false });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
