//Production
//const apiUrl = "";

//Test
let env = "production";
let baseUrl = "";
let apiUrl = "";
let paymentSuccessUrl = "";
let paymentCancelledUrl = "";
let publicUrl = "";

let scopeUrl = "";
if (env === "test") {
  baseUrl = "";
  scopeUrl = "";
  apiUrl = "";
  paymentSuccessUrl = "";
  paymentCancelledUrl = "";
}
//Dev
if (env === "dev") {
  baseUrl = "http://localhost:3000/";
  scopeUrl = "http://localhost:3000";
  publicUrl = "http://localhost:3000/public/";
  apiUrl = "http://localhost/veekshanam/nms-api/public/api/";
  paymentSuccessUrl = "http://localhost:3000/payment-success/";
  paymentCancelledUrl = "http://localhost:3000/payment-cancelled";
}

if (env === "production") {
  baseUrl = "https://nms.veekshanam.com/";
  apiUrl = "https://nms.veekshanam.com/services/public/api/";
  publicUrl = "https://nms.veekshanam.com/public/";
  paymentSuccessUrl = "https://nms.veekshanam.com/payment-success/";
  paymentCancelledUrl = "https://nms.veekshanam.com/payment-cancelled";
}

export {
  apiUrl,
  paymentSuccessUrl,
  paymentCancelledUrl,
  baseUrl,
  scopeUrl,
  publicUrl,
};
