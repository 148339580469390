import React from "react";

export class RetCopyRow extends React.Component {
  constructor() {
    super();
    this.state = {
      is_hide: false,
      input: {
        scheme: 0,
        free: 0,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let data = this.props.data;
    let { input } = this.state;
    input = {
      scheme: data.paid || 0,
    };
    this.setState({ input });
    this.props.setDataArray(input, data.id);
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    this.addAmounts();
  }

  addAmounts() {
    let { input } = this.state;
    this.setState({ input });
    let data = this.props.data;
    this.props.setDataArray(input, data.id);
  }

  render() {
    let data = this.props.data;
    return (
      <React.Fragment>
        <tr>
          <td>{data.name}</td>
          <td>{data.code}</td>
          <td>
            <input
              type="text"
              className="table-input"
              name="paid"
              value={this.state.input.paid}
              onChange={this.handleChange}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
