import React from "react";
import { getLoggedUser } from "../../../state/user";

const user = getLoggedUser();
export class DashboardContents extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {user.role !== "AD" && (
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>Settings</h3>
                      <p>Master Settings</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-settings"></i>
                    </div>
                  </div>
                </div>
              )}
              {user.role !== "AD" && (
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>Circulation</h3>
                      <p>Manage circulations</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person"></i>
                    </div>
                  </div>
                </div>
              )}
              {user.role !== "AD" && (
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>Reports</h3>
                      <p>Reports</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                  </div>
                </div>
              )}

              {(user.role === "A" || user.role === "AD") && (
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <a href="/list_ads" className="text-white">
                        <h3>Advertisements</h3>
                      </a>

                      <a href="/list_ads" className="text-white">
                        <p>Manage Advertisements</p>
                      </a>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
