import React from "react";
export class PreLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      is_hide: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
  }
  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.state.is_hide
              ? "d-none"
              : "preloader flex-column justify-content-center align-items-center"
          }
        >
          <img
            className="animation__shake"
            src={process.env.PUBLIC_URL + "/dist/img/ico.png"}
            alt="AdminLTELogo"
            height="60"
            width="60"
          />
        </div>
      </React.Fragment>
    );
  }
}
