import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import DataTable from "react-data-table-component";
import { getAllAgencys } from "../../../models/agencies";
import { getAllDistricts } from "../../../models/districts";
import {
  createBranch,
  getBranches,
  updateBranch,
  getBranch,
  deleteBranch,
} from "../../../models/branches";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { getAllModes } from "../../../models/subs_mode";

export class Branches extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      modes: [],
      districts: [],
      subscriptions: [],
      subscription: [],
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 10,
      },
      input: {},
      update: {},
      query: "",
      is_loading: false,
      is_edit_loading: false,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Name",
          cell: (row) => row.name,
        },
        {
          name: "Address",
          cell: (row) => `${row.address || ""} ${row.address2 || ""}`,
        },
        {
          name: "View",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-edit"></i>&nbsp; View
            </button>
          ),
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp;Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openView = this.openView.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadAgencies();
    this.loadDistricts();
    //this.loadModes();

    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.loadFreeSubs(q);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district") {
      this.loadAgencies();
    }
  }

  handleUpdateChange(event) {
    let update = this.state.update;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "district") {
      this.loadAgencies("u");
    }
  }

  async loadModes() {
    let response = await getAllModes();
    if (response.success) {
      this.setState({ modes: response.data });
    }
  }

  async loadAgencies(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    if (typeof dist === "undefined") {
      return;
    }
    let q = new URLSearchParams({
      district: dist,
    }).toString();

    let response = await getAllAgencys(q);
    if (response.success) {
      this.setState({ agencies: response.data });
      let { update } = this.state;
      if (type === "u") {
        this.setState({ update });
      }
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadFreeSubs(q) {
    this.setState({ is_loading: true });
    let response = await getBranches(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ subscriptions: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    let q = new URLSearchParams(pinput).toString();
    this.loadFreeSubs(q);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      const response = await createBranch(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadFreeSubs(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.update;
      const response = await updateBranch(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadFreeSubs(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id, update: {} });
    this.getData(id);
  }

  async getData(id) {
    this.setState({ is_edit_loading: true });
    let response = await getBranch(id);
    console.log(response);
    if (response.success) {
      this.setState({ update: response.data });
      this.loadAgencies("u");
      setTimeout(() => {
        let data = response.data;
        this.setState({ update: data });
        this.setState({ is_edit_loading: false });
      }, 500);
    } else {
      this.setState({ is_edit_loading: false });
    }
  }

  async removeRow(id) {
    let response = await deleteBranch(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      let pinput = this.state.page_input;
      let q = new URLSearchParams(pinput).toString();
      this.loadFreeSubs(q);
    }
  }

  handleOptionChange = (selectedOption) => {
    let { input } = this.state;
    input.agency = selectedOption;
    this.setState({ input });
  };

  handleOptionChangeU = (selectedOption) => {
    let { update } = this.state;
    update.agency = selectedOption;
    this.setState({ update });
  };

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name + " - " + item.code };
    });
    return opt;
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Branches</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isOpen: !this.state.isOpen });
                            }}
                          >
                            Add New Branch
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of branches</h3>
                        </div>

                        <div className="card-body p-0">
                          <div className="row">
                            <div className="col-md-12">
                              {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.subscriptions.length > 0 && (
                                  <DataTable
                                    title=""
                                    fixedHeader
                                    columns={this.state.columns}
                                    data={this.state.subscriptions}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={15}
                                    paginationDefaultPage={
                                      this.state.currentPage
                                    }
                                    paginationRowsPerPageOptions={[
                                      15, 25, 50, 100,
                                    ]}
                                    paginationComponentOptions={{
                                      rowsPerPageText: "Branches per page:",
                                      rangeSeparatorText: "out of",
                                    }}
                                    onChangePage={this.handlePageChange}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          show={this.state.isOpen}
          onHide={() => {
            this.setState({ isOpen: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="add-scheme">Add New Branch</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputReceipt">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="name"
                                  value={this.state.input.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputReceipt">Address 1</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 1"
                                  name="address"
                                  value={this.state.input.address}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputReceipt">Address 2</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 2"
                                  name="address2"
                                  value={this.state.input.address2}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-save" />
                &nbsp; Save
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                <i className="fa fa-times" />
                &nbsp; Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          size="xl"
          show={this.state.popup}
          onHide={() => {
            this.setState({ popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">View/Edit Subscriber</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleUpdate}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.is_edit_loading && (
                        <div className="text-center p-5">
                          <Spinner animation="grow" size="lg" />
                        </div>
                      )}
                      {!this.state.is_edit_loading && (
                        <div className="card card-secondary">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="inputReceipt">Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="name"
                                    value={this.state.update.name}
                                    onChange={this.handleUpdateChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="inputReceipt">Address 1</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 1"
                                    name="address"
                                    value={this.state.update.address}
                                    onChange={this.handleUpdateChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label for="inputReceipt">Address 2</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 2"
                                    name="address2"
                                    value={this.state.update.address2}
                                    onChange={this.handleUpdateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            {!this.state.is_edit_loading && (
              <Modal.Footer>
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-save" />
                  &nbsp; Save
                </button>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => {
                    this.setState({ popup: false });
                  }}
                >
                  <i className="fa fa-times" />
                  &nbsp; Close
                </button>
              </Modal.Footer>
            )}
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
