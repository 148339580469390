import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const statuses = {
  A: "Approved",
  N: "Not Approved",
  D: "Deleted",
};

const user = getLoggedUser();

async function getUsers() {
  return fetch(apiUrl + "users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getStaffUsers() {
  return fetch(apiUrl + "staff_users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function createUser(payload) {
  return fetch(apiUrl + "users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateUser(payload, id) {
  return fetch(apiUrl + "users/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

function activateUser(id) {
  return fetch(apiUrl + `activate_user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

function deActivateUser(id) {
  return fetch(apiUrl + `de_activate_user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUserChartData() {
  return fetch(apiUrl + "user_reg_chart_data", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function googleLogin(payload) {
  return fetch(apiUrl + "google/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function facebookLogin(payload) {
  return fetch(apiUrl + "facebook/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendBulkMail(payload) {
  return fetch(apiUrl + "bulk_mail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function adminAuth(payload) {
  return fetch(apiUrl + "admin_auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export {
  createUser,
  getUsers,
  deleteUser,
  getUser,
  updateUser,
  getUserChartData,
  googleLogin,
  facebookLogin,
  sendBulkMail,
  getStaffUsers,
  activateUser,
  deActivateUser,
  adminAuth,
  statuses,
};
