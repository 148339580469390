import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllAgencys } from "../../../models/agencies";
import { getAllDistricts } from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";
import { getAllLabels } from "../../../models/labels";
import { PrintRow } from "./helpers/print_row";
import { apiUrl } from "../../../constants/global";

export class PrintLabel extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      districts: [],
      labels: [],
      totalRows: 0,
      currentPage: 1,
      input: {},
      is_all_selected: false,
      update: {},
      query: "",
      is_loading: false,
      data_arr: {},
      is_saving: false,
      selected_ids: [],
      total_amount: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.searchData = this.searchData.bind(this);
    this.setDataArray = this.setDataArray.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadDistricts();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district" && event.target.value) {
      this.loadAgencies();
    }
    if (event.target.name === "district" && !event.target.value) {
      input.agency = "";
      this.setState({ input, agencies: [] });
    }
  }

  triggerChange(event) {
    let { is_all_selected } = this.state;
    is_all_selected = !this.state.is_all_selected;
    this.setState({ is_all_selected });
    if (is_all_selected) {
      let selected_ids = this.state.labels.map((item) => item.id);
      this.setState({ selected_ids });
    } else {
      this.setState({ selected_ids: [] });
    }
  }

  async loadAgencies() {
    let q = new URLSearchParams({
      district: this.state.input.district,
    }).toString();
    let { input } = this.state;
    let response = await getAllAgencys(q);
    if (response.success && response.data.length) {
      this.setState({ agencies: response.data });
    } else {
      input.agency = "";
      this.setState({ agencies: [], input });
      swal("Failed", "No agencies found !!!", "error");
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async searchData() {
    if (!this.state.input.label_date) {
      swal("Failed", "Please enter a date", "error");
      return;
    }
    if (!this.state.input.district) {
      swal("Failed", "Please select a district", "error");
      return;
    }
    this.setState({ is_loading: true });
    let q = new URLSearchParams({
      label_date: this.state.input.label_date || "",
      district: this.state.input.district || "",
      agency: this.state.input.agency ? this.state.input.agency.value : "",
    }).toString();
    let response = await getAllLabels(q);
    if (response.success) {
      this.setState({ total_amount: response.meta.total_amount });
      this.setState({ labels: response.data });
      this.setState({ is_loading: false });
    }
  }

  handleOptionChange = (selectedOption) => {
    let { input } = this.state;
    input.agency = selectedOption;
    this.setState({ input });
  };

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name + " - " + item.code };
    });
    return opt;
  }

  setDataArray(id, check) {
    console.log(id, check);
    let { selected_ids } = this.state;
    let index = selected_ids.indexOf(id);
    if (id && check) {
      selected_ids.push(id);
    }
    if (index >= 0 && !check) {
      console.log("rem");
      selected_ids.splice(index, 1);
    }
    this.setState({ selected_ids });
  }

  render() {
    let agencyOptions = this.selectOptions(this.state.agencies);
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Print Label" />
            <SideBar />
            <div className="content-wrapper mt-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">
                            Filter by District
                          </h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="label_date"
                                  value={this.state.input.label_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Agency</label>
                                <Select
                                  name="agency"
                                  className="form-control p-0 border-0 custom-select"
                                  value={this.state.input.agency}
                                  onChange={this.handleOptionChange}
                                  options={agencyOptions}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">&nbsp;</label>
                                <button
                                  className="form-control btn btn-primary"
                                  onClick={this.searchData}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-light">
                        <div className="card-header d-flex">
                          <h3 className="card-title m-auto">
                            List of labels ({this.state.selected_ids.length}{" "}
                            selected)
                          </h3>
                          {this.state.selected_ids.toString() === "" && (
                            <button
                              className="btn btn-outline-dark"
                              disabled={true}
                            >
                              <i className="fa fa-print" />
                              &nbsp;Print
                            </button>
                          )}
                          {this.state.selected_ids.toString() !== "" && (
                            <a
                              href={
                                apiUrl +
                                "print_labels?ids=" +
                                this.state.selected_ids.toString()
                              }
                              className="btn btn-outline-info"
                              target="_new"
                            >
                              <i className="fa fa-print" />
                              &nbsp;Print
                            </a>
                          )}
                        </div>

                        <div
                          className="card-body table-responsive p-0"
                          style={{ height: "420px" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                {this.state.is_loading && (
                                  <div className="text-center p-5">
                                    <Spinner animation="grow" size="lg" />
                                  </div>
                                )}

                                {!this.state.is_loading &&
                                  !this.state.labels.length && (
                                    <div className="m-3 p-3">
                                      <div className="alert alert-danger text-center">
                                        No data found!!!
                                      </div>
                                    </div>
                                  )}

                                {!this.state.is_loading &&
                                  this.state.labels.length > 0 && (
                                    <table class="table table-head-fixed table-striped">
                                      <thead>
                                        <tr>
                                          <th className="text-center">
                                            <input
                                              className="cust-cb"
                                              type="checkbox"
                                              name="is_all_selected"
                                              value={this.state.is_all_selected}
                                              onChange={this.triggerChange}
                                            />
                                          </th>
                                          <th>AGENCY</th>
                                          <th>CODE</th>
                                          <th>SCHEME</th>
                                          <th>FREE</th>
                                          <th>PAID</th>
                                          <th>
                                            TOTAL({this.state.total_amount})
                                          </th>
                                          <th>DATE</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.labels.map((item, i) => {
                                          return (
                                            <PrintRow
                                              data={item}
                                              key={i}
                                              selected_ids={
                                                this.state.selected_ids
                                              }
                                              setDataArray={this.setDataArray}
                                            />
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
