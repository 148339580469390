import React from "react";

export class LabelRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_hide: false,
      input: {
        add_def_free: 0,
        add_monthly_paid: 0,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let data = this.props.data;
    console.log(this.props.key);
    let { input } = this.state;
    input = {
      defadvance: data.def_scheme,
      deffree: data.def_free,
      defpaid: data.monthly_paid,
      add_def_free: 0,
      add_monthly_paid: 0,
      addadvance: data.def_scheme,
      addfree: Number(data.def_free),
      addpaid: Number(data.monthly_paid),
      route_name: data.route_name,
    };
    this.setState({ input });
    this.props.setDataArray(input, data.id);
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    this.addAmounts();
  }

  addAmounts() {
    let { input } = this.state;
    input.addadvance = input.defadvance;
    input.addfree = Number(input.deffree) + Number(input.add_def_free);
    input.addpaid = Number(input.defpaid) + Number(input.add_monthly_paid);
    this.setState({ input });
    let data = this.props.data;
    input.route_name = data.route_name;
    console.log(input);
    this.props.setDataArray(input, data.id);
  }
  render() {
    let data = this.props.data;
    return (
      <React.Fragment>
        <tr key={this.props.index}>
          <td>{this.props.index + 1}</td>
          <td>{data.name}</td>
          <td>{data.route_name}</td>
          <td>{data.code}</td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="def_scheme"
              value={this.state.input.defadvance}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="def_free"
              value={this.state.input.deffree}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="monthly_paid"
              value={this.state.input.defpaid}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              name="add_def_free"
              value={this.state.input.add_def_free}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              name="add_monthly_paid"
              value={this.state.input.add_monthly_paid}
              onChange={this.handleChange}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="addadvance"
              value={this.state.input.addadvance}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="addfree"
              value={this.state.input.addfree}
            />
          </td>
          <td>
            <input
              type="text"
              className="table-input"
              readOnly={true}
              name="addpaid"
              value={this.state.input.addpaid}
            />
          </td>
          {/* <td>
            <button
              type="text"
              className="btn btn-outline-success btn-sm"
              onClick={() => {
                this.addAmounts();
              }}
            >
              <i className="fa fa-plus" />
            </button>
          </td> */}
        </tr>
      </React.Fragment>
    );
  }
}
