import React from "react";
import { Routes, Route } from "react-router-dom";
import { Registration } from "../auth/registration";
import Login from "../auth/login";
import { ForgotPassword } from "../auth/forgotPassword";
import { NotFound } from "../notfound";
import { Dashboard } from "../pages/dashboard";
import { CreateLabel } from "../pages/circluation/create_label";
import { PrintLabel } from "../pages/circluation/print_label";
import { DailyBill } from "../pages/circluation/daily_bill";
import { MonthlyBill } from "../pages/circluation/monthly_bill";
import { Agency } from "../pages/master/agency";
import { Scheme } from "../pages/master/scheme";
import { FreeSubscription } from "../pages/master/free_subscription";
import { Branches } from "../pages/master/branches";
import { Reporters } from "../pages/master/reporters";
import { SubscriptionModes } from "../pages/master/subscription_modes";
import { BundleDropping } from "../pages/master/bundle_dropping";
import { AgencyRoutes } from "../pages/master/agency_routes";
import { Niyojakamandalams } from "../pages/master/niyojakamandalams";
import { Districts } from "../pages/master/districts";
import { Ledger } from "../pages/circluation/ledger";
import { ReturnCopies } from "../pages/circluation/return_copies";
import { Costs } from "../pages/master/costs";
import { Blocks } from "../pages/master/blocks";
import { Mandalams } from "../pages/master/mandalams";
import { Notifications } from "../pages/notifications";
import { PrintOrder } from "../pages/print_order";
import { getLoggedUser } from "../../state/user";
import { CreateAds } from "../pages/ads/create_ads";
import { AdAgencies } from "../pages/ads/agencies";
import { ListAds } from "../pages/ads/list_ads";
import { ReportAds } from "../pages/ads/report";
import { Reports } from "../pages/circluation/reports";
import { ReportsLabels } from "../pages/circluation/reports_label";
const user = getLoggedUser();
const isLogged = localStorage.getItem("is_logged_in");
// function logout() {
//   localStorage.removeItem("is_logged_in");
//   localStorage.removeItem("token");
//   localStorage.removeItem("veeks_user_info");
//   window.location.href = "/";
// }
export class NavRoutes extends React.Component {
  componentDidMount() {}

  hideSideBar() {
    document.body.classList.remove("active");
  }
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={isLogged ? <Dashboard /> : <Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/ledger"
            element={isLogged ? <Ledger /> : <NotFound />}
          />
          <Route
            path="/create_label"
            element={isLogged ? <CreateLabel /> : <NotFound />}
          />
          <Route
            path="/print_label"
            element={isLogged ? <PrintLabel /> : <NotFound />}
          />
          <Route
            path="/daily_bill"
            element={isLogged ? <DailyBill /> : <NotFound />}
          />
          <Route
            path="/monthly_bill"
            element={isLogged ? <MonthlyBill /> : <NotFound />}
          />
          <Route
            path="/reports-bill"
            element={isLogged ? <Reports /> : <NotFound />}
          />
          <Route
            path="/reports-label"
            element={isLogged ? <ReportsLabels /> : <NotFound />}
          />
          <Route
            path="/return_copies"
            element={isLogged ? <ReturnCopies /> : <NotFound />}
          />
          <Route path="/costs" element={isLogged ? <Costs /> : <NotFound />} />
          <Route
            path="/agency"
            element={isLogged ? <Agency /> : <NotFound />}
          />
          <Route
            path="/scheme"
            element={isLogged ? <Scheme /> : <NotFound />}
          />
          <Route
            path="/free_subscription"
            element={isLogged ? <FreeSubscription /> : <NotFound />}
          />
          <Route
            path="/districts"
            element={
              isLogged && user.role === "A" ? <Districts /> : <NotFound />
            }
          />
          <Route
            path="/blocks"
            element={isLogged ? <Blocks /> : <NotFound />}
          />
          <Route
            path="/mandalams"
            element={isLogged ? <Mandalams /> : <NotFound />}
          />
          <Route
            path="/niyojakamandalams"
            element={isLogged ? <Niyojakamandalams /> : <NotFound />}
          />

          <Route
            path="/agency_routes"
            element={isLogged ? <AgencyRoutes /> : <NotFound />}
          />

          <Route
            path="/bundle_dropping"
            element={isLogged ? <BundleDropping /> : <NotFound />}
          />

          <Route
            path="/subscription_modes"
            element={isLogged ? <SubscriptionModes /> : <NotFound />}
          />

          <Route
            path="/reporters"
            element={isLogged ? <Reporters /> : <NotFound />}
          />

          <Route
            path="/branches"
            element={isLogged ? <Branches /> : <NotFound />}
          />
          <Route
            path="/notifications"
            element={isLogged ? <Notifications /> : <NotFound />}
          />
          <Route
            path="/print_order"
            element={isLogged ? <PrintOrder /> : <NotFound />}
          />

          <Route
            path="/create_ads"
            element={
              isLogged && (user.role === "A" || user.role === "AD") ? (
                <CreateAds />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/list_ads"
            element={
              isLogged && (user.role === "A" || user.role === "AD") ? (
                <ListAds />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/ad_agencies"
            element={
              isLogged && (user.role === "A" || user.role === "AD") ? (
                <AdAgencies />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/ad_reports"
            element={
              isLogged && (user.role === "A" || user.role === "AD") ? (
                <ReportAds />
              ) : (
                <NotFound />
              )
            }
          />

          <Route path="*" exact={true} element={<NotFound />} />
        </Routes>
      </React.Fragment>
    );
  }
}
