import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const endpoint = apiUrl + "reporters";
const user = getLoggedUser();

async function createReporter(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllReporters() {
  return fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getReporter(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getReporterByAgency(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "get_reporter_by_agency",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function updateReporter(payload, id) {
  return fetch(endpoint + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteReporter(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createReporter,
  getAllReporters,
  getReporter,
  getReporterByAgency,
  updateReporter,
  deleteReporter,
};
