import React from "react";
import { SideBar } from "../layouts/sidebar";
import { TopMenu } from "../layouts/top_menu";
import DataTable from "react-data-table-component";
import { getAllAgencys } from "../../models/agencies";
import { getAllDistricts } from "../../models/districts";
import {
  createPrintOrder,
  getAllPrintOrders,
  updatePrintOrder,
  getPrintOrder,
  deletePrintOrder,
} from "../../models/print_orders";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { getAllModes } from "../../models/subs_mode";
import { tableCustomStyles } from "../../constants/styles";
import { apiUrl } from "../../constants/global";

export class PrintOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      modes: [],
      districts: [],
      printorders: [],
      printorder: [],
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 10,
      },
      search: {},
      input: {},
      update: {},
      query: "",
      is_loading: false,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "View",
          button: true,
          wrap: false,
          cell: (row) => (
            <a
              className="btn btn-outline-dark btn-sm"
              href={apiUrl + "print_order?id=" + row.id}
              target="_new"
            >
              <i className="fa fa-print"></i>&nbsp; Print
            </a>
          ),
        },
        {
          name: "Issue Date",
          cell: (row) => row.issue_date,
          sortable: true,
        },
        {
          name: "Number of color pages",
          cell: (row) => row.no_color_pages,
          sortable: true,
          wrap: true,
        },
        {
          name: "Number of B/W pages",
          cell: (row) => row.no_bw_pages,
          sortable: true,
          wrap: true,
        },
        {
          name: "Reel in stock",
          cell: (row) => row.reel_stock,
          sortable: true,
          wrap: true,
        },
        {
          name: "District #1 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district1 ? row.district1 + `(${row.copy1})` : "",
        },
        {
          name: "District #2 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district2 ? row.district2 + `(${row.copy2})` : "",
        },
        {
          name: "District #3 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district3 ? row.district3 + `(${row.copy3})` : "",
        },
        {
          name: "District #4 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district4 ? row.district4 + `(${row.copy4})` : "",
        },
        {
          name: "District #5 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district5 ? row.district5 + `(${row.copy5})` : "",
        },
        {
          name: "District #6 & Copies",
          sortable: true,
          wrap: true,
          cell: (row) =>
            row.district6 ? row.district6 + `(${row.copy6})` : "",
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp;Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openView = this.openView.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadAgencies();
    this.loadDistricts();
    this.loadModes();

    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.loadPrintOrders(q);
  }

  handleSearchChange(event) {
    let { search } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district") {
      this.loadAgencies();
    }
  }

  handleUpdateChange(event) {
    let update = this.state.update;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "district") {
      this.loadAgencies("u");
    }
  }

  async loadModes() {
    let response = await getAllModes();
    if (response.success) {
      this.setState({ modes: response.data });
    }
  }

  async loadAgencies(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    if (typeof dist === "undefined") {
      return;
    }
    let q = new URLSearchParams({
      district: dist,
    }).toString();

    let response = await getAllAgencys(q);
    if (response.success) {
      this.setState({ agencies: response.data });
      let { update } = this.state;
      if (type === "u") {
        this.setState({ update });
      }
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadPrintOrders(q) {
    this.setState({ is_loading: true });
    let response = await getAllPrintOrders(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ printorders: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    let q = new URLSearchParams(pinput).toString();
    this.loadPrintOrders(q);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      const response = await createPrintOrder(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
          closeOnClickOutside: false,
        })
          .then((value) => {
            this.setState({ isOpen: false });
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadPrintOrders(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.update;
      payload.agency = this.state.update.agency.value;
      const response = await updatePrintOrder(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        let data = response.data;
        let { update } = this.state;
        update.agency = {
          value: data.agency_info.id,
          label: data.agency_info.name + " - " + data.agency_info.code,
        };
        this.setState({ update });
        let pinput = this.state.page_input;
        let q = new URLSearchParams(pinput).toString();
        this.loadPrintOrders(q);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id });
    this.getPrintOrderData(id);
  }

  async getPrintOrderData(id) {
    let response = await getPrintOrder(id);
    console.log(response);
    if (response.success) {
      this.setState({ update: response.data });
      this.loadAgencies("u");
      setTimeout(() => {
        let data = response.data;
        let { update } = this.state;
        update.agency = {
          value: data.agency_info.id,
          label: data.agency_info.name + " - " + data.agency_info.code,
        };
        this.setState({ update });
      }, 500);
    }
  }

  async removeRow(id) {
    let response = await deletePrintOrder(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      let pinput = this.state.page_input;
      let q = new URLSearchParams(pinput).toString();
      this.loadPrintOrders(q);
    }
  }

  handleOptionChange = (selectedOption) => {
    let { input } = this.state;
    input.agency = selectedOption;
    this.setState({ input });
  };

  handleOptionChangeU = (selectedOption) => {
    let { update } = this.state;
    update.agency = selectedOption;
    this.setState({ update });
  };

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name + " - " + item.code };
    });
    return opt;
  }

  handleSearch() {
    let { search } = this.state;
    let pinput = this.state.page_input;
    pinput.receipt_no =
      typeof search.receipt_no !== "undefined" ? search.receipt_no : "";
    pinput.district =
      typeof search.district !== "undefined" ? search.district : "";
    this.setState({ page_input: pinput });
    let q = new URLSearchParams(pinput).toString();
    this.loadPrintOrders(q);
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Print Order</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isOpen: !this.state.isOpen });
                            }}
                          >
                            Add Print Order
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of print orders</h3>
                        </div>

                        <div className="card-body p-0 overflow-hidden">
                          <div className="row">
                            <div className="col-md-12 px-3 pt-3 bg-dark">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      name="district"
                                      value={this.state.search.district}
                                      onChange={this.handleSearchChange}
                                    >
                                      <option value="">
                                        --Select District--
                                      </option>
                                      {this.state.districts.length > 0 &&
                                        this.state.districts.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <button
                                      type="button"
                                      className="btn btn-outline-light btn-block"
                                      onClick={this.handleSearch}
                                    >
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.printorders.length > 0 && (
                                  <DataTable
                                    customStyles={tableCustomStyles}
                                    title=""
                                    fixedHeader
                                    columns={this.state.columns}
                                    data={this.state.printorders}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={15}
                                    paginationDefaultPage={
                                      this.state.currentPage
                                    }
                                    paginationRowsPerPageOptions={[
                                      15, 25, 50, 100,
                                    ]}
                                    paginationComponentOptions={{
                                      rowsPerPageText: "Orders per page:",
                                      rangeSeparatorText: "out of",
                                    }}
                                    onChangePage={this.handlePageChange}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          show={this.state.isOpen}
          onHide={() => {
            this.setState({ isOpen: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="add-printorder">Add Print Order</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Edition</label>
                                <select
                                  className="form-control"
                                  name="edition"
                                  value={this.state.input.edition}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Date of Issue</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="issue_date"
                                  value={this.state.input.issue_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="">No. of color pages</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Number of color pages"
                                  name="no_color_pages"
                                  value={this.state.input.no_color_pages}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="">No. of B/W pages</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Number of B/W"
                                  name="no_bw_pages"
                                  value={this.state.input.no_bw_pages}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="">Reel in stock(in KG)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Reel in stock"
                                  name="reel_stock"
                                  value={this.state.input.reel_stock}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">District</th>
                                    <th scope="col">Number of Copies</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district1"
                                        value={this.state.input.district1}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy1"
                                        value={this.state.input.copy1}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district2"
                                        value={this.state.input.district2}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy2"
                                        value={this.state.input.copy2}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">3</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district3"
                                        value={this.state.input.district3}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy3"
                                        value={this.state.input.copy3}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">4</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district4"
                                        value={this.state.input.district4}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy4"
                                        value={this.state.input.copy4}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">5</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district5"
                                        value={this.state.input.district5}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy5"
                                        value={this.state.input.copy5}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">6</th>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="district6"
                                        value={this.state.input.district6}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="copy6"
                                        value={this.state.input.copy6}
                                        onChange={this.handleChange}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-save" />
                &nbsp; Save
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                <i className="fa fa-times" />
                &nbsp; Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
