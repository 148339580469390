import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllDistricts } from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { apiUrl } from "../../../constants/global";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../constants/styles";
import { labelReport } from "../../../models/labels";

export class ReportsLabels extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      filter_agencies: [],
      agencies: [],
      districts: [],
      data: [],
      input: {
        limit: 50,
        page: 1,
      },
      query: "",
      is_loading: false,
      is_ag_loading: false,
      currentPage: 1,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Agency",
          sortable: true,
          cell: (row) => row.agency_name,
        },
        {
          name: "Agency Code",
          sortable: true,
          cell: (row) => row.agency_code,
        },
        {
          name: "Scheme",
          sortable: true,
          cell: (row) => row.addadvance,
        },
        {
          name: "Free",
          sortable: true,
          cell: (row) => row.addfree,
        },
        {
          name: "Paid",
          sortable: true,
          cell: (row) => row.addpaid,
        },
        {
          name: "Deposit",
          sortable: true,
          cell: (row) => row.deposit,
        },
        {
          name: "Opening Balance",
          sortable: true,
          cell: (row) => row.opening_balance,
        },
        {
          name: "Bill Amount",
          sortable: true,
          cell: (row) => row.bill_amount,
        },
        {
          name: "Received Amount",
          sortable: true,
          cell: (row) => row.received_amount,
        },
        {
          name: "Repaid Amount",
          sortable: true,
          cell: (row) => row.repaid_amount,
        },
        {
          name: "Distribution Amount",
          sortable: true,
          cell: (row) => row.distribution_cost,
        },
        {
          name: "Return Amount",
          sortable: true,
          cell: (row) => row.return_amount,
        },
        {
          name: "Net Amount",
          sortable: true,
          cell: (row) => row.net_amount,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  componentDidMount() {
    let { input } = this.state;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    input.month = currentMonth;
    input.year = currentYear;
    this.setState({ input });
    this.loadDistricts();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handlePageChange = (page) => {
    let { input } = this.state;
    input.page = page;
    this.setState({ currentPage: page });
    this.setState({ input: input });
    this.handleSearch();
  };

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async handleSearch() {
    let { input } = this.state;
    if (!input.month) {
      swal("Failed", "Month is mandatory", "error");
      return;
    }
    if (!input.year) {
      swal("Failed", "Year is mandatory", "error");
      return;
    }
    if (!input.district) {
      swal("Failed", "District field is mandatory", "error");
      return;
    }
    // if (!input.agency) {
    //   swal("Failed", "Agency field is mandatory", "error");
    //   return;
    // }
    this.setState({ is_loading: true });
    let response = await labelReport(input);
    if (response.success) {
      this.setState({ data: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }
  objectToQueryString(obj) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");
  }

  render() {
    let { data } = this.state;
    let months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    let years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Report : Labels" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">Filter</h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Month</label>
                                <select
                                  className="form-control"
                                  name="month"
                                  value={this.state.input.month}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select a month--</option>
                                  {months.length > 0 &&
                                    months.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Year</label>
                                <select
                                  className="form-control"
                                  name="year"
                                  value={this.state.input.year}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select a year--</option>
                                  {years.length > 0 &&
                                    years.map((item) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">&nbsp;</label>
                                <button
                                  className="btn btn-primary btn-block"
                                  onClick={this.handleSearch}
                                >
                                  <i className="fa fa-file-invoice"></i>
                                  &nbsp;Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  {this.state.is_loading && (
                    <div className="text-center p-5">
                      <Spinner animation="grow" size="lg" />
                    </div>
                  )}
                  {!this.state.is_loading && Object.keys(data).length > 0 && (
                    <div className="d-flex justify-content-end">
                      <a
                        href={
                          apiUrl +
                          "label_excel?" +
                          this.objectToQueryString(this.state.input)
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="border border-success p-1"
                      >
                        <i className="fa fa-file-excelfa fa-file-excel fa-2x text-success" />
                      </a>
                    </div>
                  )}
                  {!this.state.is_loading && Object.keys(data).length > 0 && (
                    <DataTable
                      customStyles={tableCustomStyles}
                      title=""
                      fixedHeader
                      columns={this.state.columns}
                      data={this.state.data}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={this.state.totalRows}
                      paginationPerPage={15}
                      paginationDefaultPage={this.state.currentPage}
                      paginationRowsPerPageOptions={[50, 100, 200, 500]}
                      paginationComponentOptions={{
                        rowsPerPageText: "Data per page:",
                        rangeSeparatorText: "out of",
                      }}
                      onChangePage={this.handlePageChange}
                    />
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
