import React from "react";

export class PrintRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllSelected: false,
      is_hide: false,
      is_check: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {}

  handleChange(event) {
    let { is_check } = this.state;
    is_check = !this.state.is_check;
    let data = this.props.data;
    this.props.setDataArray(data.id, is_check);
    this.setState({
      is_check,
    });
  }

  render() {
    let data = this.props.data;
    let number_of_copies =
      Number(data.addadvance) + Number(data.addfree) + Number(data.addpaid);
    let selected_ids = this.props.selected_ids;
    return (
      <React.Fragment>
        <tr key={this.props.key}>
          <td className="text-center">
            <input
              className="cust-cb-child"
              type="checkbox"
              checked={selected_ids.includes(data.id)}
              name="is_check"
              value={this.state.is_check}
              onClick={this.handleChange}
            />
          </td>
          <td>{data.agency_info && data.agency_info.name ? data.agency_info.name : ""}</td>
          <td>{data.agency_info && data.agency_info.code ? data.agency_info.code : ""}</td>
          <td>{data.addadvance}</td>
          <td>{data.addfree}</td>
          <td>{data.addpaid}</td>
          <td>{number_of_copies}</td>
          <td>{data.label_date}</td>
        </tr>
      </React.Fragment>
    );
  }
}
