import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import DataTable from "react-data-table-component";
import { getAllDistricts } from "../../../models/districts";
import {
  createAdAgency,
  getAllAdAgencys,
  updateAdAgency,
  getAdAgency,
  deleteAdAgency,
} from "../../../models/ad_agencies";
import { Spinner } from "react-bootstrap";
import { getReporterByAgency } from "../../../models/reporters";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { getAllBlocks } from "../../../models/blocks";
import { getAllRoutes } from "../../../models/routes";
import { getAllBdPlaces } from "../../../models/bdplaces";
import { tableCustomStyles } from "../../../constants/styles";
import { getAllMandalams } from "../../../models/mandalam";

export class AdAgencies extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      agencies: [],
      reporters: [],
      districts: [],
      blocks: [],
      mandalams: [],
      bdplaces: [],
      routes: [],
      branches: [],
      bills: [],
      bill: [],
      totalRows: 0,
      currentPage: 1,
      search: {
        status: true,
      },
      page_input: {
        page: 1,
        limit: 15,
        status: true,
      },
      input: {},
      input_nj: {},
      update: {},
      update_nj: {},
      query: "",
      is_loading: false,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Name",
          cell: (row) => row.name,
          sortable: true,
        },
        {
          name: "GST",
          cell: (row) => row.gst,
          sortable: true,
        },
        {
          name: "District",
          sortable: true,
          cell: (row) =>
            row && row.district_info ? row.district_info.name : "",
        },
        {
          name: "View",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-edit"></i>&nbsp; View
            </button>
          ),
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp;Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.openView = this.openView.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadDistricts();
    let input = this.state.page_input;
    let q = new URLSearchParams(input).toString();
    this.loadAgencies(q);
  }

  handleSearchChange(event) {
    let { search } = this.state;
    search[event.target.name] = event.target.value;
    this.setState({ search });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleUpdateChange(event) {
    let update = this.state.update;
    update[event.target.name] = event.target.value;
    this.setState({ update });
  }

  handleOptionChange = (selectedOption) => {
    let { input_nj } = this.state;
    input_nj.block = selectedOption;
    this.setState({ input_nj });
    this.loadMandalams();
  };
  handleMandOptionChange = (selectedOption) => {
    let { input_nj } = this.state;
    input_nj.mandalam = selectedOption;
    this.setState({ input_nj });
    this.loadMandalams();
    this.loadRoutes();
    this.loadBdPlaces();
  };

  handleOptionChangeU = (selectedOption) => {
    let { update_nj } = this.state;
    update_nj.block = selectedOption;
    this.setState({ update_nj });
    this.loadMandalams("u");
  };
  handleMandOptionChangeU = (selectedOption) => {
    let { update_nj } = this.state;
    update_nj.mandalam = selectedOption;
    this.setState({ update_nj });
    this.loadRoutes("u");
    this.loadBdPlaces("u");
  };

  async loadReporters(agency) {
    let response = await getReporterByAgency({ agency: agency });
    if (response.success) {
      this.setState({ reporters: response.data });
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadAgencies(q) {
    this.setState({ is_loading: true });
    let response = await getAllAdAgencys(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ bills: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadBlocks(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    if (typeof dist === "undefined") {
      return;
    }
    let q = new URLSearchParams({
      district: dist,
    }).toString();
    let response = await getAllBlocks(q);
    if (response.success) {
      this.setState({ blocks: response.data });
    }
  }

  async loadMandalams(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    let block =
      type === "u"
        ? this.state.update_nj.block.value
        : this.state.input_nj.block.value;
    if (typeof dist === "undefined") {
      return;
    }
    let q = new URLSearchParams({
      district: dist,
      block: typeof block !== "undefined" ? block : false,
    }).toString();
    let response = await getAllMandalams(q);
    if (response.success) {
      this.setState({ mandalams: response.data });
    }
  }

  async loadRoutes(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    let block =
      type === "u"
        ? this.state.update_nj.block.value
        : this.state.input_nj.block.value;

    let q = new URLSearchParams({
      district: typeof dist !== "undefined" ? dist : "",
      block: typeof block !== "undefined" ? block : "",
    }).toString();
    let response = await getAllRoutes(q);
    if (response.success) {
      this.setState({ routes: response.data });
    }
  }

  async loadBdPlaces(type) {
    let dist =
      type === "u" ? this.state.update.district : this.state.input.district;
    let block =
      type === "u"
        ? this.state.update_nj.block.value
        : this.state.input_nj.block.value;

    let q = new URLSearchParams({
      district: typeof dist !== "undefined" ? dist : "",
      block: typeof block !== "undefined" ? block : "",
    }).toString();
    let response = await getAllBdPlaces(q);
    if (response.success) {
      this.setState({ bdplaces: response.data });
    }
  }

  handlePageChange = (page) => {
    let input = this.state.page_input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAgencies(qstring);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      if (!payload.name || !payload.district) {
        swal(
          "Failed",
          "Plese enter mandatory fields(Name,Code,District & Neojamandalam)",
          "error"
        );
        return;
      }
      // payload.block =
      //   this.state.input_nj.block && this.state.input_nj.block.value
      //     ? this.state.input_nj.block.value
      //     : null;
      const response = await createAdAgency(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadAgencies(this.state.qstring);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.update;
      if (!payload.name || !payload.district) {
        swal(
          "Failed",
          "Plese enter mandatory fields(Name,Code,District & Neojamandalam)",
          "error"
        );
        return;
      }
      payload.block =
        this.state.update_nj.block && this.state.update_nj.block.value
          ? this.state.update_nj.block.value
          : null;
      payload.mandalam =
        this.state.update_nj.mandalam && this.state.update_nj.mandalam.value
          ? this.state.update_nj.mandalam.value
          : null;
      const response = await updateAdAgency(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadAgencies(this.state.qstring);
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  openView(id) {
    this.setState({ popup: true, selectedID: id });
    this.getData(id);
  }

  async getData(id) {
    this.setState({ update_nj: {} });
    this.setState({ update: [] });

    let response = await getAdAgency(id);
    if (response.success) {
      this.setState({ update: response.data });
      this.loadBlocks("u");
      this.loadBdPlaces("u");
      this.loadRoutes("u");
      setTimeout(() => {
        let data = response.data;
        let { update_nj } = this.state;
        update_nj.block = {
          value: data.block_info.id,
          label: data.block_info.name,
        };
        update_nj.mandalam = {
          value: data.mandalam_info.id,
          label: data.mandalam_info.name,
        };
        this.setState({ update_nj });
      }, 100);
    }
  }

  async removeRow(id) {
    let response = await deleteAdAgency(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      this.loadAgencies(this.state.qstring);
    }
  }

  selectOptions(options) {
    let opt = options.map((item, i) => {
      return { value: item.id, label: item.name };
    });
    return opt;
  }

  handleSearch() {
    let { search } = this.state;
    let pinput = this.state.page_input;
    pinput.name = typeof search.name !== "undefined" ? search.name : "";
    pinput.code = typeof search.code !== "undefined" ? search.code : "";
    pinput.place = typeof search.place !== "undefined" ? search.place : "";
    pinput.status = typeof search.status !== "undefined" ? search.status : "";
    pinput.district =
      typeof search.district !== "undefined" ? search.district : "";
    let q = new URLSearchParams(pinput).toString();
    this.setState({ qstring: q });
    this.loadAgencies(q);
  }

  render() {
    // let neojOptions = this.selectOptions(this.state.blocks);
    // let manOptions = this.selectOptions(this.state.mandalams);
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Agency</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              this.setState({ isOpen: !this.state.isOpen });
                            }}
                          >
                            Add New Agency
                          </button>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header">
                          <h3 className="card-title">List of Agencies</h3>
                        </div>

                        <div className="card-body p-0 overflow-hidden">
                          <div className="row">
                            <div className="col-md-12 px-3 pt-3 bg-dark">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Name"
                                      name="name"
                                      value={this.state.search.name}
                                      onChange={this.handleSearchChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      name="district"
                                      value={this.state.search.district}
                                      onChange={this.handleSearchChange}
                                    >
                                      <option value="">
                                        --Select District--
                                      </option>
                                      {this.state.districts.length > 0 &&
                                        this.state.districts.map((item, i) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <button
                                      type="button"
                                      className="btn btn-outline-light btn-block"
                                      onClick={this.handleSearch}
                                    >
                                      Search
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              {this.state.is_loading && (
                                <div className="text-center p-5">
                                  <Spinner animation="grow" size="lg" />
                                </div>
                              )}

                              {!this.state.is_loading &&
                                this.state.bills.length > 0 && (
                                  <DataTable
                                    customStyles={tableCustomStyles}
                                    title=""
                                    fixedHeader
                                    columns={this.state.columns}
                                    data={this.state.bills}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={this.state.input.limit}
                                    paginationDefaultPage={
                                      this.state.currentPage
                                    }
                                    paginationRowsPerPageOptions={[
                                      20, 50, 100, 200, 500,
                                    ]}
                                    paginationComponentOptions={{
                                      rowsPerPageText: "Bills per page:",
                                      rangeSeparatorText: "out of",
                                    }}
                                    onChangePage={this.handlePageChange}
                                    onChangeRowsPerPage={(e) => {
                                      let { input } = this.state;
                                      input.limit = e;
                                      this.setState({ input });
                                      this.handlePageChange(
                                        this.state.currentPage
                                      );
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="xl"
          show={this.state.isOpen}
          onHide={() => {
            this.setState({ isOpen: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-bill">Create Agency</Modal.Title>
          </Modal.Header>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">New Agency</h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">Agency Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Agency Name"
                                  name="name"
                                  value={this.state.input.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">GST</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GST"
                                  name="gst"
                                  value={this.state.input.gst}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">
                                  Agency Name(Mal)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Agency Name(Mal)"
                                  name="name_mal"
                                  value={this.state.input.name_mal}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div> 
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputAmount">Agency Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Code"
                                  name="code"
                                  value={this.state.input.code}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>*/}
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={(e) => {
                                    this.handleChange(e);
                                    this.loadBlocks();
                                  }}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary btn-sm">
                Save
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        {/* Update section */}
        <Modal
          size="xl"
          show={this.state.popup}
          onHide={() => {
            this.setState({ popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="edit-bill">View/Edit Agency</Modal.Title>
          </Modal.Header>

          <form autoComplete="off" onSubmit={this.handleUpdate}>
            <Modal.Body>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">
                            View/Edit Agency
                          </h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">Agency Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Agency Name"
                                  name="name"
                                  value={this.state.update.name}
                                  onChange={this.handleUpdateChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">GST</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GST"
                                  name="gst"
                                  value={this.state.update.gst}
                                  onChange={this.handleUpdateChange}
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputReceipt">
                                  Agency Name(Mal)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Agency Name(Mal)"
                                  name="name_mal"
                                  value={this.state.update.name_mal}
                                  onChange={this.handleUpdateChange}
                                />
                              </div>
                            </div> 
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputAmount">Agency Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Code"
                                  name="code"
                                  value={this.state.update.code}
                                  onChange={this.handleUpdateChange}
                                />
                              </div>
                            </div>*/}
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.update.district}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                    this.loadBlocks();
                                  }}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary btn-sm">
                Update Agency
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => {
                  this.setState({ popup: false });
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
