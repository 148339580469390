import React from "react";
import { PreLoader } from "../layouts/pre_loader";
import { TopMenu } from "../layouts/top_menu";
import { SideBar } from "../layouts/sidebar";
import { DashboardContents } from "./helpers/dashboard_contents";

export class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="hold-transition sidebar-mini layout-fixed">
        <div className="wrapper">
          <PreLoader />
          <TopMenu />
          <SideBar />

          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Dashboard</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <DashboardContents />
          </div>
        </div>
      </div>
    );
  }
}
