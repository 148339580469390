import React from "react";
import { NavLink, Link } from "react-router-dom";
import { getLoggedUser } from "../../state/user";
import logo from "../../ico.png";
const user = getLoggedUser();

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("veeks_user_info");
  window.location.href = "/";
}

const sections = {
  "/": "dashboard",
  "/ledger": "circulation",
  "/create_label": "circulation",
  "/print_label": "circulation",
  "/daily_bill": "circulation",
  "/monthly_bill": "circulation",
  "/return_copies": "circulation",
  "/reports-bill": "circulation",
  "/reports-label": "circulation",
  "/agency": "master",
  "/districts": "master",
  "/scheme": "master",
  "/free_subscription": "master",
  "/niyojakamandalams": "master",
  "/agency_routes": "master",
  "/bundle_dropping": "master",
  "/subscription_modes": "master",
  "/reporters": "master",
  "/branches": "master",
  "/costs": "master",
  "/blocks": "master",
  "/mandalams": "master",
  "/create_ads": "adv",
  "/list_ads": "adv",
  "/ad_agencies": "adv",
  "/ad_reports": "adv",
};

function getSection() {
  const { pathname } = window.location;
  return sections[pathname];
}
export class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let section = getSection();
    return (
      <React.Fragment>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="/" className="brand-link">
            <img
              src={logo}
              alt="Veekshanam Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: "0.8" }}
            />
            <span className="brand-text font-weight-light">Veekshanam</span>
          </Link>

          <div className="sidebar">
            <div className="user-panel mt-1 pb-1 mb-1 d-flex justify-content-center">
              <div className="info">
                <Link to="/#" className="d-block">
                  Welcome {user.username}
                </Link>
              </div>
            </div>

            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li
                  className={
                    section === "dashboard"
                      ? "nav-item menu-is-opening menu-open active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/"
                    className={
                      section === "dashboard" ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="nav-icon fas fa-tachometer-alt"></i>
                    <p>Dashboard</p>
                  </Link>
                </li>
                {(user.role === "A" || user.role === "BA") && (
                  <li
                    className={
                      section === "circulation"
                        ? "nav-item menu-is-opening menu-open active"
                        : "nav-item"
                    }
                  >
                    <Link
                      exact
                      to=""
                      className={
                        section === "circulation"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <i className="nav-icon fas fa-upload"></i>
                      <p>
                        Circulation
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <NavLink exact to="/create_label" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Create Label</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/print_label" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Print Label</p>
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                      <NavLink exact to="/ledger" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Ledger</p>
                      </NavLink>
                    </li> */}
                      <li className="nav-item">
                        <NavLink exact to="/daily_bill" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Daily Bill</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/return_copies" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Return Copies</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/monthly_bill" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Monthly Bill</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/reports-bill" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Reports</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/reports-label" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Reports - Label</p>
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Distribute Bill</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Return Bill</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Agency Subscription</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Cheque Return</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Sub Agency</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Vehicle Allowance</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact to="/#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Reporter Expense</p>
                      </NavLink>
                    </li> */}
                    </ul>
                  </li>
                )}

                {(user.role === "A" || user.role === "BA") && (
                  <li
                    className={
                      section === "master"
                        ? "nav-item menu-is-opening menu-open"
                        : "nav-item"
                    }
                  >
                    <Link
                      to={true}
                      className={
                        section === "master" ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="nav-icon fas fa-cog"></i>
                      <p>
                        Master Settings
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <NavLink exact to="/agency" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Agency</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/scheme" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Scheme Subscription</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/free_subscription"
                          className="nav-link"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>Free Subscriptions</p>
                        </NavLink>
                      </li>
                      {user && user.role === "A" && (
                        <li className="nav-item">
                          <NavLink exact to="/districts" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Districts</p>
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/niyojakamandalams"
                          className="nav-link"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>Niyojaka Mandalam</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/agency_routes" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Route</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/bundle_dropping"
                          className="nav-link"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>Bundle Dropping</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/subscription_modes"
                          className="nav-link"
                        >
                          <i className="far fa-circle nav-icon"></i>
                          <p>Subscription Mode</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/reporters" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Reporter</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/blocks" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Blocks</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/mandalams" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Mandalam</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/branches" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Branch</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/costs" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Cost settings</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/publication" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Publication</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/edition" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Edition</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/category" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Category</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/copies" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Copies</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/holidays" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Holidays</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/client" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Client</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}

                {/* {(user.role === "A" || user.role === "BA") && (
                  // <li
                  //   className={
                  //     section === "reports"
                  //       ? "nav-item menu-is-opening menu-open"
                  //       : "nav-item"
                  //   }
                  // >
                  //   <Link
                  //     to={true}
                  //     className={
                  //       section === "reports" ? "nav-link active" : "nav-link"
                  //     }
                  //   >
                  //     <i className="nav-icon fas fa-file"></i>
                  //     <p>
                  //       Reports
                  //       <i className="right fas fa-angle-left"></i>
                  //     </p>
                  //   </Link>
                  //   {/* <ul className="nav nav-treeview">
                  //   <li className="nav-item">
                  //     <NavLink
                  //       exact
                  //       to="/./index.html"
                  //       className="nav-link active"
                  //     >
                  //       <i className="far fa-circle nav-icon"></i>
                  //       <p>Dashboard v1</p>
                  //     </NavLink>
                  //   </li>
                  //   <li className="nav-item">
                  //     <NavLink
                  //       exact
                  //       to="/./index2.html"
                  //       className="nav-link"
                  //     >
                  //       <i className="far fa-circle nav-icon"></i>
                  //       <p>Dashboard v2</p>
                  //     </NavLink>
                  //   </li>
                  //   <li className="nav-item">
                  //     <NavLink exact to="/#" className="nav-link">
                  //       <i className="far fa-circle nav-icon"></i>
                  //       <p>Dashboard v3</p>
                  //     </NavLink>
                  //   </li>
                  // </ul> 
                  // </li>
                )} */}
                {(user.role === "A" || user.role === "BA") && (
                  <li class="nav-item">
                    <NavLink exact to="/print_order" className="nav-link">
                      <i className="nav-icon fas fa-print"></i>
                      <p>Print Order</p>
                    </NavLink>
                  </li>
                )}

                {(user.role === "A" || user.role === "AD") && (
                  <li
                    className={
                      section === "adv"
                        ? "nav-item menu-is-opening menu-open active"
                        : "nav-item"
                    }
                  >
                    <Link
                      to={true}
                      className={
                        section === "adv" ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="nav-icon fas fa-ad"></i>
                      <p>
                        Advertisements
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <NavLink exact to="/create_ads" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Add advertisements</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/list_ads" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>List advertisements</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/ad_agencies" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Manage Client/Agency</p>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink exact to="/ad_reports" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Report</p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
                <li class="nav-item">
                  <Link exact to={true} class="nav-link" onClick={logout}>
                    <i class="nav-icon fas fa-sign-out-alt"></i>
                    <p>Logout</p>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </React.Fragment>
    );
  }
}
