const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "13px",
      fontWeight: "bold",
      paddingLeft: "0 8px",
      justifyContent: "center",
      backgroundColor: "#FFA500",
    },
  },
};

export { tableCustomStyles };
