import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllAgencys } from "../../../models/agencies";
import { getAllDistricts } from "../../../models/districts";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { generateBill } from "../../../models/bills";
import { apiUrl } from "../../../constants/global";

export class MonthlyBill extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      filter_agencies: [],
      agencies: [],
      districts: [],
      data: [],
      input: {},
      query: "",
      is_loading: false,
      is_ag_loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.generateBill = this.generateBill.bind(this);
  }
  componentDidMount() {
    let { input } = this.state;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    input.month = currentMonth;
    input.year = currentYear;
    this.setState({ input });
    this.loadDistricts();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district" && event.target.value) {
      this.loadAgencies();
    }
    if (event.target.name === "agency") {
      if (event.target.value) {
        this.loadAgencies(false);
      } else {
        this.loadAgencies();
      }
    }
  }

  async loadAgencies(is_load_filter = true) {
    this.setState({ is_ag_loading: true });
    let filter = {};
    if (this.state.input.agency) {
      filter.id = this.state.input.agency;
    }
    if (this.state.input.district) {
      filter.district = this.state.input.district;
    }
    let q = new URLSearchParams(filter).toString();
    let response = await getAllAgencys(q);
    if (response.success && response.data.length) {
      this.setState({ is_ag_loading: false });
      this.setState({ agencies: response.data });
      if (is_load_filter) {
        this.setState({ filter_agencies: response.data });
      }
    } else {
      this.setState({ agencies: [] });
      this.setState({ is_ag_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  async loadDistricts() {
    let response = await getAllDistricts();
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async generateBill() {
    let { input } = this.state;
    if (!input.month) {
      swal("Failed", "Month is mandatory", "error");
      return;
    }
    if (!input.year) {
      swal("Failed", "Year is mandatory", "error");
      return;
    }
    if (!input.district) {
      swal("Failed", "District field is mandatory", "error");
      return;
    }
    if (!input.agency) {
      swal("Failed", "Agency field is mandatory", "error");
      return;
    }
    this.setState({ is_loading: true });
    let response = await generateBill(input);
    if (response.success) {
      this.setState({ data: response.data });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }
  render() {
    let { data } = this.state;
    let months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    let years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Monthly Bill" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">Filter</h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Month</label>
                                <select
                                  className="form-control"
                                  name="month"
                                  value={this.state.input.month}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select a month--</option>
                                  {months.length > 0 &&
                                    months.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="from_date"
                                  value={this.state.input.from_date}
                                  onChange={this.handleChange}
                                /> */}
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Year</label>
                                <select
                                  className="form-control"
                                  name="year"
                                  value={this.state.input.year}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select a year--</option>
                                  {years.length > 0 &&
                                    years.map((item) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    })}
                                </select>
                                {/* <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="to_date"
                                  value={this.state.input.to_date}
                                  onChange={this.handleChange}
                                /> */}
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Agency</label>
                                <select
                                  className="form-control"
                                  name="agency"
                                  value={this.state.input.agency}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.filter_agencies.length > 0 &&
                                    this.state.filter_agencies.map(
                                      (item, i) => {
                                        return (
                                          <option value={item.id}>
                                            {item.name} - {item.code}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">&nbsp;</label>
                                <button
                                  className="btn btn-primary btn-block"
                                  onClick={this.generateBill}
                                >
                                  <i className="fa fa-file-invoice"></i>
                                  &nbsp;Generate Bill
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  {this.state.is_loading && (
                    <div className="text-center p-5">
                      <Spinner animation="grow" size="lg" />
                    </div>
                  )}

                  {!this.state.is_loading && Object.keys(data).length > 0 && (
                    <div className="row" id="printablediv">
                      <div className="col-md-12">
                        <div className="card card-warning">
                          <div
                            className="card-body p-5 m-5"
                            style={{
                              marginLeft: "6rem !important",
                              marginRight: "6rem !important",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <h3 className="p-0 m-0">
                                      VEEKSHANAM PRINTING & PUBLISHING CO.LTD
                                    </h3>
                                    <div>
                                      {data &&
                                      data.branch &&
                                      data.branch.address
                                        ? data.branch.address
                                        : ""}
                                    </div>
                                    <div>
                                      {data &&
                                      data.branch &&
                                      data.branch.address2
                                        ? data.branch.address2
                                        : ""}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="row mt-5">
                                      <div className="col-md-6">
                                        <div>
                                          Agency Code :{" "}
                                          {data.agency ? data.agency.code : ""}
                                        </div>
                                        <div>
                                          Name :{" "}
                                          {data.agency ? data.agency.name : ""}
                                        </div>
                                        <div>
                                          Place :{" "}
                                          {data.agency ? data.agency.place : ""}
                                        </div>
                                      </div>
                                      <div className="col-md-3"></div>
                                      <div className="col-md-3">
                                        <div>Date : {data.today}</div>
                                        <div>Bill No : {data.bill_no}</div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="row mt-5">
                                      <div className="col-md-12">
                                        Current Bill - {data.f_date} to{" "}
                                        {data.t_date}
                                      </div>
                                      <div className="col-md-12">
                                        <table class="table table-bordered table-striped">
                                          <thead>
                                            <tr>
                                              <th>Publication</th>
                                              <th className="text-center">
                                                Paid
                                              </th>
                                              <th className="text-center">
                                                Scheme
                                              </th>
                                              <th className="text-center">
                                                Free
                                              </th>
                                              <th className="text-center">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Ordinary</td>
                                              <td className="text-center">
                                                {data.paid_total}
                                              </td>
                                              <td className="text-center">
                                                {data.scheme_total}
                                              </td>
                                              <td className="text-center">
                                                {data.free_total}
                                              </td>
                                              <td className="text-center"></td>
                                            </tr>
                                          </tbody>
                                          <tfoot>
                                            <tr>
                                              <th>Payable Amount</th>
                                              <th className="text-center">
                                                {data.paid_total_amount}
                                              </th>
                                              <th className="text-center">
                                                {data.scheme_total_amount}
                                              </th>
                                              <th className="text-center">
                                                {data.free_total_amount}
                                              </th>
                                              <th className="text-center">
                                                {data.total_bill_amoount}
                                              </th>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>

                                      <div className="col-md-12 mt-5">
                                        <table class="table table-bordered table-striped">
                                          <tbody>
                                            <tr>
                                              <td>Opening Balance</td>
                                              <td>
                                                Rs. {data.opening_balance}/-
                                              </td>
                                              <td>Distribution Cost</td>
                                              <td>
                                                Rs. {data.distribution_cost}/-
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Current Bill</td>
                                              <td>
                                                Rs. {data.total_bill_amoount}/-
                                              </td>
                                              <td>Return Cost</td>
                                              <td>
                                                Rs. {data.total_returns_amount}
                                                /-
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td>Bill Paid</td>
                                              <td>
                                                Rs. {data.total_db_amount}/-
                                              </td>
                                            </tr>
                                          </tbody>
                                          <tfoot>
                                            <tr>
                                              <th>Total</th>
                                              <th>Rs. {data.total_1}/-</th>
                                              <th>Total</th>
                                              <th>Rs. {data.total_2}/-</th>
                                            </tr>
                                            <tr>
                                              <th>Net Balance</th>
                                              <th colSpan={3}>
                                                Rs. {data.net_amount}/-
                                              </th>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!this.state.is_loading && Object.keys(data).length > 0 && (
                    <div className="row">
                      <div className="col-md-12 m-auto text-center">
                        <a
                          className="btn btn-dark"
                          target="_new"
                          href={
                            apiUrl +
                            "print_bill?" +
                            new URLSearchParams(this.state.input).toString()
                          }
                        >
                          <i className="fa fa-print"></i>&nbsp;Print Now
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
