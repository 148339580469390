import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllDistricts } from "../../../models/districts";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { getAllAdAgencys } from "../../../models/ad_agencies";
import { getActiveGST } from "../../../models/gst";
import {
  createAdvertisement,
  getAllClients,
  getLatestBillNumber,
} from "../../../models/advertisements";
import { getBranches } from "../../../models/branches";
import { getLoggedUser } from "../../../state/user";

const user = getLoggedUser();

export class CreateAds extends React.Component {
  constructor() {
    super();
    this.state = {
      published_editions: {},
      qstring: "",
      selectedID: null,
      popup: false,
      is_hide: false,
      isOpen: false,
      branches: [],
      agencies: [],
      districts: [],
      clients: [],
      totalRows: 0,
      currentPage: 1,
      gst: {
        sgst: 0,
        cgst: 0,
        igst: 0,
      },
      input: {
        agency_gst: "",
        discount: 0,
      },
      update: {},
      query: "",
      is_loading: false,
      is_saving: false,
      incr: 0,
      client_popup: false,
      is_client_loading: false,
      net_amount: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMultipleSelectChange =
      this.handleMultipleSelectChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.loadActiveGST = this.loadActiveGST.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.setNetAmount = this.setNetAmount.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.getBillNumber();
    this.loadBranches();
    this.loadDistricts();
    this.loadActiveGST();
    this.loadClients();
  }

  async getBillNumber() {
    const response = await getLatestBillNumber();
    let { input } = this.state;
    input.bill_number = response.data.bill_number;
    this.setState({ input });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district" && event.target.value) {
      this.loadAgencies();
    }
    if (event.target.name === "ad_agency" && event.target.value) {
      let { agencies, input } = this.state;
      let agency = agencies.find(
        (item) => Number(item.id) === Number(event.target.value)
      );
      input.agency_gst = agency.gst;
      this.setState({ input });
    }
    if (event.target.name === "rate" && event.target.value) {
      let { input } = this.state;
      let bill_amount =
        Number(input.ad_height) * Number(input.ad_width) * Number(input.rate);
      input.bill_amount = bill_amount;
      input.taxable_amount = bill_amount;
      this.setState({ input });
      this.setState({ net_amount: bill_amount });
    }
    if (event.target.name === "discount" && event.target.value) {
      let { input } = this.state;
      let taxable_amount =
        Number(input.bill_amount) -
        (Number(input.bill_amount) * Number(event.target.value)) / 100;
      input.taxable_amount = taxable_amount;
      this.setState({ input });
      this.setState({ net_amount: taxable_amount });
    }
    if (event.target.name === "bill_amount" && event.target.value) {
      let { input } = this.state;
      input.taxable_amount = event.target.value;
      let taxable_amount = 0;
      if (input.discount > 0) {
        let taxable_amount =
          Number(event.target.value) -
          (Number(event.target.value) * Number(input.discount)) / 100;
        input.taxable_amount = taxable_amount;
      }
      this.setState({ input });
      this.setState({ net_amount: taxable_amount });
    }
  }

  handleMultipleSelectChange(event) {
    const { name, options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    this.setState({
      input: {
        ...this.state.input,
        [name]: selectedValues,
      },
    });
  }

  async loadAgencies() {
    this.setState({ is_loading: true });
    this.setState({ agencies: {} });
    let q = new URLSearchParams({
      is_label: true,
      district: this.state.input.district,
    }).toString();
    let response = await getAllAdAgencys(q);
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ agencies: response.data });
    } else {
      this.setState({ agencies: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  setNetAmount() {
    let { input, net_amount, gst } = this.state;
    this.setState({
      net_amount: input.taxable_amount,
    });
    let net_gst = 0;
    if (input.is_sgst) {
      net_gst += Number(gst.sgst);
    }
    if (input.is_cgst) {
      net_gst += Number(gst.cgst);
    }
    if (input.is_igst) {
      net_gst += Number(gst.igst);
    }
    net_amount =
      Number(input.taxable_amount) +
      Number((input.taxable_amount * net_gst) / 100);
    this.setState({ net_amount });
  }

  async loadClients() {
    this.setState({ clients: {}, is_client_loading: true });
    let response = await getAllClients();
    if (response.success) {
      this.setState({ clients: response.data, is_client_loading: false });
    } else {
      this.setState({ clients: [], is_client_loading: false });
    }
  }

  async loadBranches() {
    let q = "all=1";
    let response = await getBranches(q);
    if (response.success) {
      this.setState({ branches: response.data });
    }
  }

  async loadDistricts() {
    let q = "all=1";
    let response = await getAllDistricts(q);
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadActiveGST() {
    let response = await getActiveGST();
    if (response.success) {
      this.setState({ gst: response.data });
    }
  }

  async saveData() {
    let mandatory_fields = [
      "category",
      "bill_number",
      "bill_date",
      "ro_number",
      "ro_date",
      "edition",
      "published_editions",
      "district",
      "insertion_date",
      "insertion_page",
      "ad_type",
      "bill_amount",
      "taxable_amount",
    ];
    let missingFields = [];
    for (let field of mandatory_fields) {
      if (!this.state.input[field]) {
        missingFields.push(field);
      }
    }
    if (missingFields.length > 0) {
      swal("Failed", "All fields are mandatory", "error");
      return;
    }
    this.setState({ is_saving: true });
    let payload = this.state.input;
    let response = await createAdvertisement(payload);
    if (response.success) {
      this.setState({ is_saving: false });
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          setTimeout(function () {
            window.location.reload();
          }, 50);
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ is_saving: false });
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Create Advertisement" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">
                            Add Advertisement
                          </h3>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Bill Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Bill number"
                                  name="bill_number"
                                  value={this.state.input.bill_number}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Bill Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Bill date"
                                  name="bill_date"
                                  value={this.state.input.bill_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">RO Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="RO number"
                                  name="ro_number"
                                  value={this.state.input.ro_number}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">RO Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="RO date"
                                  name="ro_date"
                                  value={this.state.input.ro_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Category</label>
                                <select
                                  className="form-control"
                                  name="category"
                                  value={this.state.input.category}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Private">Private</option>
                                  <option value="PRD">PRD</option>
                                  <option value="Lottery">Lottery</option>
                                  <option value="DAVP">
                                    {user.role === "A" ? "Magazine" : "DAVP"}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Edition</label>
                                <select
                                  className="form-control"
                                  name="edition"
                                  value={this.state.input.edition}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.branches.length > 0 &&
                                    this.state.branches.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Agency</label>
                                <select
                                  className="form-control"
                                  name="ad_agency"
                                  value={this.state.input.ad_agency}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.agencies.length > 0 &&
                                    this.state.agencies.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Agency GST</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GST"
                                  name="agency_gst"
                                  value={this.state.input.agency_gst}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Client</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Client"
                                  name="client"
                                  value={this.state.input.client}
                                  onChange={this.handleChange}
                                  style={{ width: "85%" }}
                                />
                                <button
                                  className="btn btn-warning"
                                  style={{
                                    position: "absolute",
                                    top: "2rem",
                                    right: "0",
                                  }}
                                  onClick={(event) => {
                                    this.setState({ client_popup: true });
                                    this.loadClients();
                                  }}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Client GST</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Client GST"
                                  name="client_gst_no"
                                  value={this.state.input.client_gst_no}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Insertion Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Insertion date"
                                  name="insertion_date"
                                  value={this.state.input.insertion_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                                <label for="inputDate">Insertion page</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Insertion page"
                                  name="insertion_page"
                                  value={this.state.input.insertion_page}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">
                                  Advertisement type
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Advertisement type"
                                  name="ad_type"
                                  value={this.state.input.ad_type}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="inputDate">
                                      Ad height(in CM)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ad height"
                                      name="ad_height"
                                      value={this.state.input.ad_height}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label for="inputDate">
                                      Ad width(in CM)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Ad width"
                                      name="ad_width"
                                      value={this.state.input.ad_width}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Rate</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Rate"
                                  name="rate"
                                  value={this.state.input.rate}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Bill amount</label>
                                <input
                                  type="readonly"
                                  className="form-control"
                                  placeholder="Bill Amount"
                                  name="bill_amount"
                                  value={this.state.input.bill_amount}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Discount(%)</label>
                                <input
                                  type="readonly"
                                  className="form-control"
                                  placeholder="Discount"
                                  name="discount"
                                  value={this.state.input.discount}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">Taxable amount</label>
                                <input
                                  type="readonly"
                                  className="form-control"
                                  placeholder="Taxable Amount"
                                  name="taxable_amount"
                                  value={this.state.input.taxable_amount}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="is_sgst"
                                  checked={this.state.input.is_sgst}
                                  onChange={(event) => {
                                    let { input } = this.state;
                                    input.is_sgst = !this.state.input.is_sgst;
                                    this.setState({
                                      input: input,
                                    });
                                    this.setNetAmount();
                                  }}
                                  disabled={this.state.input.is_igst}
                                />
                                &nbsp;
                                <label for="inputDate">
                                  Is SGST ({this.state.gst.sgst}%) applicable.?
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="is_cgst"
                                  checked={this.state.input.is_cgst}
                                  onChange={(event) => {
                                    let { input } = this.state;
                                    input.is_cgst = !this.state.input.is_cgst;
                                    this.setState({
                                      input: input,
                                    });
                                    this.setNetAmount();
                                  }}
                                  disabled={this.state.input.is_igst}
                                />
                                &nbsp;
                                <label for="inputDate">
                                  Is CGST ({this.state.gst.cgst}%) applicable.?
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="is_igst"
                                  checked={this.state.input.is_igst}
                                  onChange={(event) => {
                                    let { input } = this.state;
                                    input.is_igst = !this.state.input.is_igst;
                                    this.setState({
                                      input: input,
                                    });
                                    this.setNetAmount();
                                  }}
                                  disabled={
                                    this.state.input.is_sgst ||
                                    this.state.input.is_cgst
                                  }
                                />
                                &nbsp;
                                <label for="inputDate">
                                  Is IGST ({this.state.gst.igst}%) applicable.?
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">
                                  Net Amount : Rs. {this.state.net_amount}/-
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <label for="inputDate">
                                  Published Edition(s)
                                </label>
                                <select
                                  className="form-control"
                                  name="published_editions"
                                  value={this.state.input.published_editions}
                                  onChange={this.handleMultipleSelectChange}
                                  multiple={true}
                                >
                                  <option value="0">All Kerala</option>
                                  {this.state.branches.length > 0 &&
                                    this.state.branches.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>

                          {this.state.is_saving && (
                            <div className="row">
                              <div className="text-center p-5">
                                <Spinner animation="grow" size="lg" />
                              </div>
                            </div>
                          )}

                          <div className="">
                            <div className="float-right">
                              <button
                                type="button"
                                className="btn btn-primary btn-md px-5"
                                onClick={this.saveData}
                              >
                                <i className="fa fa-save" />
                                &nbsp;
                                <span>Save</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={this.state.client_popup}
          onHide={() => {
            this.setState({ client_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">Registered Clients</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  {this.state.is_client_loading && (
                    <div className="text-center p-5">
                      <Spinner animation="grow" size="lg" />
                    </div>
                  )}
                  {!this.state.is_client_loading && (
                    <div
                      className="col-sm-12"
                      style={{ height: "50vh;", overflow: "scroll" }}
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <td>Client Name</td>
                            <td>GST Number</td>
                            <td>Select</td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.clients.length > 0 &&
                            this.state.clients.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.client}</td>
                                  <td>{item.client_gst_no}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-info"
                                      onClick={() => {
                                        let { input } = this.state;
                                        input.client = item.client;
                                        input.client_gst_no =
                                          item.client_gst_no;
                                        this.setState({ input });
                                        this.setState({ client_popup: false });
                                      }}
                                    >
                                      Select
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                this.setState({ client_popup: false });
              }}
            >
              <i className="fa fa-times" />
              &nbsp; Close
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
