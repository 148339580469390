import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { updateCost, getCost } from "../../../models/costs";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

export class Costs extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      is_loading: false,
      selectedID: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }
  componentDidMount() {
    this.getInitCost();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleUpdate = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      const response = await updateCost(payload, this.state.selectedID);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  async getInitCost() {
    this.setState({ is_loading: true });
    let response = await getCost(this.state.selectedID);
    if (response.success) {
      this.setState({ input: response.data, is_loading: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Cost Settings" />
            <SideBar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <h1>Cost Settings</h1>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {this.state.is_loading && (
                  <div className="text-center p-5">
                    <Spinner animation="grow" size="lg" />
                  </div>
                )}

                {!this.state.is_loading && (
                  <form autoComplete="off" onSubmit={this.handleUpdate}>
                    <section className="content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card card-secondary">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="inputReceipt">
                                        Copy Rate
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Copy rate"
                                        name="copy_rate"
                                        value={this.state.input.copy_rate}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="inputReceipt">
                                        Scheme Rate
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Scheme rate"
                                        name="scheme_rate"
                                        value={this.state.input.scheme_rate}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="inputReceipt">
                                        Free Copy Rate
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Free Copy rate"
                                        name="free_rate"
                                        value={this.state.input.free_rate}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <label for="inputReceipt">&nbsp;</label>
                                    <button
                                      type="submit"
                                      className="form-control btn btn-primary"
                                    >
                                      <i className="fa fa-save" />
                                      &nbsp; Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </form>
                )}
              </section>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
